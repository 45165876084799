// *** Edwin
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// *** Edwin Tab
import * as React from 'react';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';


import "./DashboardAppPage.css";

// ** Date Formating
import dayjs from "dayjs";

// components

// @mui
import {
    Grid,
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Tooltip,
    Checkbox,
    TableRow,
    Badge,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    CircularProgress,
    TableContainer,
    TablePagination,
    Chip,
    Button,
    Select,
    MenuItem
} from '@mui/material';

import { filter } from 'lodash';

// Popus
import { v4 as uuidv4 } from 'uuid';
import InsertAdvancess from './InsertAdvancess'
import InsertAccounts from './InsertAccounts'
import InsertCrIn from './InsertCrIn'
import EditAccounts from './EditAccounts'
import InsertPurch from './InsertPurch'
import InsertAdvUsed from './InsertAdvUsed'
import InsertCrOut from './InsertCrOut'
import FileProgressBar from './FileProgressBar'


import Loader from '../components/loader/Loader';
import { Get100Days, Get100DaysStatus, GetStatus, GetUserDetails } from '../components/api/DailyAccountApiService';
// components
import Iconify from '../components/iconify';
import Label from '../components/label';
// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../sections/@dashboard/app';


import Scrollbar from '../components/scrollbar';
// sections
import { UserListHeadNNP, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { variables } from '../Variables';
import CustomizedDatePopup from '../Popups/CustomizedDatePopup';





const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',

    boxShadow: 24,
    p: 4,
};





// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'company', label: 'Company', alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
    { id: 'isVerified', label: 'Verified', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '' },
];

const ACCOINT_TABLE = [
    { id: 'date', label: 'Dt', alignRight: false },
    { id: 'opcash', label: 'Op Cash', alignRight: false },
    { id: 'sales', label: 'Sales', alignRight: false },
    { id: 'nonbill', label: 'Non Bill', alignRight: false },
    { id: 'advance', label: 'Advance', alignRight: false },
    { id: 'crin', label: 'CR In', alignRight: false },
    { id: 'cashin', label: 'Cash In', alignRight: false },
    { id: 'TotalIn', label: '', alignRight: false },
    { id: 'Adjest', label: '', alignRight: false },
    { id: 'pur', label: 'Purchase', alignRight: false },
    { id: 'card', label: 'Card', alignRight: false },
    { id: 'gpay', label: 'GPay', alignRight: false },
    { id: 'oldbill', label: 'Old Bill', alignRight: false },
    { id: 'advused', label: 'Adv: Used', alignRight: false },
    { id: 'exp', label: 'Exp  ', alignRight: false },
    { id: 'crout', label: 'CR Out', alignRight: false },
    { id: 'take', label: 'Take', alignRight: false },
    { id: 'cashbox', label: 'Cash Box', alignRight: false },
    { id: 'TotalOut', label: '', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },

];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

function GetGraphData(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].sales);
}
function GetGraphDataPurchase(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].purchase);
}
function GetGraphDataPayment(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].payment);
}
function GetGraphDataTake(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].take);
}
function GetGraphDataPur(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].pur);
}
function GetGraphDataDate(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].date);
}

function applySortFilter2(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function addClick() {
    console.log('ssss');
}

const dropdownOptions = [
    { value: 'KO', label: 'KO' },
    { value: 'MD', label: 'MD' },
];


export default function DashboardAppPage() {
    const current = new Date();
    const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const theme = useTheme();


    const [open, setOpen] = useState(false);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('date');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(65);
    const [loader, setLoader] = useState(false);


    /** 
    http://apassant.net/2012/01/16/timeout-for-html5-localstorage/
  
  var hours = 24; // Reset when storage is more than 24hours
  var now = new Date().getTime();
  var setupTime = localStorage.getItem('setupTime');
  if (setupTime == null) {
      localStorage.setItem('setupTime', now)
  } else {
      if(now-setupTime > hours*60*60*1000) {
          localStorage.clear()
          localStorage.setItem('setupTime', now);
      }
  }
    
    */

    const [lastActiveIn, setlastActiveIn] = useState();
    const [loginuserDetailId, setloginuserDetailId] = useState();
    const [userPunchStatus, setuserPunchStatus] = useState();
    const [userPunchTxt, setuserPunchTxt] = useState();
    const [userName, setuserName] = useState();
    const [userImage, setuserImage] = useState();
    const [show, setShow] = useState(true);






    // *** Edwin
    const [account, setUserAccount] = useState([{}]);
    const [accountStatus, setAccountStatus] = useState([{}]);

    const [Userrr, setUserLis] = useState([{}]);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date);


    const fetchUserData = async (logid) => {
        try {
            setLoader(true);
            const res = await GetUserDetails(logid, 0);
            if (res.data) {
                setUserLis(res.data);
            }
            setLoader(false);
        } catch (error) {
            console.error('Error in fetchUserData:', error);
        }
        // fetch(`${variables.API_URL}DailyAccounts/GetUserDetails`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         UserDetailId: logid,
        //         PunchType: 0,
        //     })
        // })
        // fetch(`${variables.API_URL}DailyAccounts/GetUserDetails`)
        // .then((response) => response.json())
        // .then(
        //     (data) => setUserLis(data));
        // console.log('GetUserDetails');
        // console.log(Userrr);
    };

    const chkUserLogin = () => {
        const b = JSON.parse(localStorage.getItem('mYLog'));

        if (b === null) {
            navigate('/login', { replace: true });
        }
        else {
            setuserName(JSON.parse(localStorage.getItem('mYLog')).displayName);
            setuserImage(JSON.parse(localStorage.getItem('mYLog')).ProfileImage);
            setloginuserDetailId(localStorage.getItem('userId'));
            setlastActiveIn(JSON.parse(localStorage.getItem('mYLog')).lastActiveIn);
            fetchUserData(JSON.parse(localStorage.getItem('mYLog')).UId);
        }

    };

    const calculateFromDate = (months) => {
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - months);
        return { fromDate, toDate };
    };

    const fetchAccountsData = async (shop, fromDate, toDate) => {
        try {
            let formattedFromDate;
            let formattedToDate;
            if (fromDate === undefined || fromDate === "" || toDate === undefined || toDate === "") {
                const date = calculateFromDate(1);
                formattedFromDate = dayjs(date.fromDate).format('YYYY-MM-DD');
                formattedToDate = dayjs(date.toDate).format('YYYY-MM-DD');
            }
            else {
                formattedFromDate = dayjs(fromDate).format('YYYY-MM-DD');
                formattedToDate = dayjs(toDate).format('YYYY-MM-DD');
            }
            let shopId;
            if (loginuserDetailId <= 3) {
                if (shop) shopId = shop;
                else shopId = "KO";
            }
            else {
                shopId = localStorage.getItem('shopId');
                if (Number(shopId) === 1) {
                    shopId = 'KO';
                }
            }
            setLoader(true);
            const res = await Get100Days(shopId, formattedFromDate, formattedToDate);
            fetchAccountsStatus(shopId, formattedFromDate, formattedToDate);
            if (res.data) {
                setUserAccount(res.data);
            }
            setLoader(false);
        } catch (error) {
            console.error('Error in fetchUserData:', error);
        }

        // fetch(`${variables.API_URL}DailyAccounts/Get100Days`)
        //     .then((response) => response.json())
        //     .then(
        //         (data) => setUserAccount(data),
        // console.log('DailyAccounts'),       

        // const stabilizedThis = account.map((el, index) => [el, index]);
        // console.log(user[0].firstName),
        // );
    };

    const fetchAccountsStatus = async (shop, fromDate, toDate) => {
        try {
            let shopId;
            if (loginuserDetailId <= 3) {
                if (shop) shopId = shop;
                else shopId = "KO";
            }
            else {
                shopId = localStorage.getItem('shopId');
                if (Number(shopId) === 1) {
                    shopId = 'KO';
                }
            }
            setLoader(true);
            const res = await Get100DaysStatus(shopId, fromDate, toDate);
            if (res.data) {
                setAccountStatus(res.data);
            }
            setLoader(false);
        } catch (error) {
            console.error('Error in fetchAccountsStatus:', error);
        }
    };

    useEffect(() => {
        const b = JSON.parse(localStorage.getItem('mYLog'));
        if (b !== null) {
            updateDashboard();
        }
        else {
            navigate('/login', { replace: true });
        }


    }, []);
    // *** End Edwin

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = ACCOINT_TABLE.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };



    // setopeningCash(account[0].opcash);

    // console.log(ss);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - account.length) : 0;

    const filteredUsers = applySortFilter(account, getComparator(order, orderBy), filterName);


    const saleGrapfDate = GetGraphDataDate(account, getComparator(order, orderBy), filterName);
    const saleGrapf = GetGraphData(account, getComparator(order, orderBy), filterName);
    const takeGrapf = GetGraphDataTake(account, getComparator(order, orderBy), filterName);
    const purchaseGrapf = GetGraphDataPurchase(account, getComparator(order, orderBy), filterName);
    const paymentGrapf = GetGraphDataPayment(account, getComparator(order, orderBy), filterName);


    const purGrapf = GetGraphDataPur(account, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    const saleIncrease1 = Math.round(((accountStatus[0].monthlyAVGSales - accountStatus[0].last_MonthlyAVGSales) / accountStatus[0].last_MonthlyAVGSales) * 100);
    const saleIncrease = `(${saleIncrease1}%) than last year`
    const monTotSale = accountStatus[0].monthlyTotalSales
    const monTotPur = accountStatus[0].purchase
    const monTotPay = accountStatus[0].payments


    const [DailyAccount, setDailyAccount] = useState([
        {
            id: 1,
            oPCash: 0,
        },
    ]);


    function updateDashboard() {

        chkUserLogin();
        fetchAccountsData();
        // fetchAccountsStatus();
        // fetchUserData(loginuserDetailId);
        // console.log(accountStatus[0].shiftopcash);
        // setopeningCash(account[0].opcash);
        // console.log('saleGrapf');
        // console.log(saleGrapf);
        // console.log(saleGrapfDate);
    }

    const [selectedValue, setSelectedValue] = useState('KO');

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
        fetchAccountsData(event.target.value);
    };

    return (
        <>
            <Helmet>
                <title> 100 Days Entry | {variables.COMPANY_NAME} </title>
            </Helmet>
            <div>

                <Container maxWidth="xl">
                    <Grid container spacing={3} padding={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff={1} title="Cash-Short" total={accountStatus[0].negative_Total} className="s1" color="error" icon={'ant-design:windows-filled'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff={1} title="Cr-Bal" total={accountStatus[0].cr_Balabce_Outstanding} className="s1" color="error" icon={'ant-  :apple-filled'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff={1} title="Non-Bill-Bal" total={accountStatus[0].balance_NonBillSales} className="s1" color="error" icon={'ant-design:apple-filled'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff={1} title="Pen: Advance" total={accountStatus[0].pending_Advance} className="s1" color="warning" icon={'ant-design:apple-filled'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff={1} title="AVG-Sales" total={accountStatus[0].monthlyAVGSales} className="s1 okk" icon={'ant-design:apple-filled'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff={1} title={`AVG-Sale (Ls-Mo)`} className="s1" total={accountStatus[0].last_MonthlyAVGSales || 0} color="info" icon={'ant-design:bug-filled'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff={1} title={`Sales (Ls-Mo)`} className="s1" total={accountStatus[0].last_MonthlyTotalSales} color="info" icon={'ant-design:bug-filled'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff='1' title="Open-Cash" total={accountStatus[0].shiftopcash === null && accountStatus[0].opcash || accountStatus[0].shiftopcash} icon={'arcticons:gnucash'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff='1' title="Balance Payment" color="error" total={accountStatus[0].outstanding_payments} icon={'arcticons:gnucash'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <AppWidgetSummary rndoff='1' title="Customer Commitments" color="error" total={accountStatus[0].commitments} icon={'arcticons:gnucash'} />
                        </Grid>
                        <Container>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                <Typography variant="h4" gutterBottom>
                                    Daily Accounts
                                </Typography>
                                {loginuserDetailId <= 3 ? (
                                    <Stack direction="row" alignItems="center" mb={2}>
                                        <Typography variant="p" gutterBottom mr={2}>
                                            Filter By :
                                        </Typography>

                                        <Select width={10} value={selectedValue} onChange={handleDropdownChange}>
                                            {dropdownOptions.map(option => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>
                                ) : ('')
                                }
                                <Button color="info" variant="contained" size='small'
                                    onClick={() => {
                                        const { fromDate, toDate } = calculateFromDate(1);
                                        fetchAccountsData(selectedValue, fromDate, toDate);
                                    }}
                                    startIcon={<Iconify icon="eva:plus-fill" />}>
                                    1 Month Records
                                </Button>
                                <Button variant="contained" size='small'
                                    onClick={() => {
                                        const { fromDate, toDate } = calculateFromDate(2);
                                        fetchAccountsData(selectedValue, fromDate, toDate);
                                    }}
                                    startIcon={<Iconify icon="eva:plus-fill" />}>
                                    2 Month Records
                                </Button>
                                <Button variant="contained" size='small'
                                    onClick={() => {
                                        const { fromDate, toDate } = calculateFromDate(3);
                                        fetchAccountsData(selectedValue, fromDate, toDate);
                                    }}
                                    startIcon={<Iconify icon="eva:plus-fill" />}>
                                    3 Month Records
                                </Button>
                                <Button variant="contained" size='small'
                                    onClick={() => {
                                        const { fromDate, toDate } = calculateFromDate(6);
                                        fetchAccountsData(selectedValue, fromDate, toDate);
                                    }}
                                    startIcon={<Iconify icon="eva:plus-fill" />}>
                                    6 Month Records
                                </Button>
                                <CustomizedDatePopup shopId={selectedValue} submit={fetchAccountsData} />
                            </Stack>

                            <Card>
                                <Scrollbar>
                                    <Paper style={{ overflow: 'auto', height: 400, width: '100%' }}>
                                        <TableContainer sx={{ height: 400, minWidth: 800 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <UserListHeadNNP
                                                    order={order}
                                                    orderBy={orderBy}
                                                    // headLabel={TABLE_HEAD}
                                                    headLabel={ACCOINT_TABLE}
                                                    rowCount={account.length}
                                                    numSelected={selected.length}
                                                    onRequestSort={handleRequestSort}
                                                    onSelectAllClick={handleSelectAllClick}
                                                />
                                                {loader ? (<div className="table-loader">
                                                    <Loader />
                                                </div>
                                                ) : (
                                                    <TableBody>
                                                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                            const { id, date, opcash, sales, nonbill, lastbillno, advance, crin, cashin, TotalIn, Adjest
                                                                , pur, card, gpay, oldbill, advused, exp, crout, take, cashbox, TotalOut, name, status
                                                                , avatarUrl, crintxt, advancetxt, purchasetxt, advanceusedtxt, creditouttxt, userDetailId
                                                                , dailyAdvTrans, dailyCrInTrans, dailyPurchTrans, dailyCrOutTrans, dailyAdvUsTrans } = row;

                                                            const shorten = name ? name.substring(0, 3) : '';

                                                            const selectedUser = selected.indexOf(name) !== -1;

                                                            const lastdayclosingcash = filteredUsers[(index > 0 && (index - 1) || index)].cashbox;
                                                            return (

                                                                <TableRow hover key={id} tabIndex={-1}>
                                                                    <TableCell align="left">
                                                                        {dayjs(date).format('DD-MMM')}
                                                                    </TableCell>
                                                                    <TableCell align="right" className={index > 2 && (lastdayclosingcash === opcash && 'c' || 'cellred') || 'c'}>{opcash}</TableCell>
                                                                    <TableCell align="right">{sales}</TableCell>
                                                                    <TableCell align="right">{nonbill}</TableCell>
                                                                    <TableCell align="right">
                                                                        {(loginuserDetailId <= 3 || index >= filteredUsers.length - 1) &&
                                                                            DailyAccount.map((Arraydata) => {
                                                                                return (
                                                                                    <InsertAdvancess
                                                                                        hide={show}
                                                                                        key={Arraydata.id}
                                                                                        id={Arraydata.id}
                                                                                        trid={id}
                                                                                        advance={advance}
                                                                                        advancetxt={advancetxt}
                                                                                        date={date}
                                                                                        dailyAdvTrans={dailyAdvTrans}
                                                                                        // oPCash={accountStatus[0].opcash}
                                                                                        updateDashboard={(e) => updateDashboard()} />
                                                                                )
                                                                            })
                                                                            ||
                                                                            <Tooltip title={advancetxt} followCursor>
                                                                                {
                                                                                    (advance === 0 && '0') ||
                                                                                    <Badge color="warning" variant="dot">{advance}</Badge>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {(loginuserDetailId <= 3 || index >= filteredUsers.length - 1) &&
                                                                            DailyAccount.map((Arraydata) => {
                                                                                return (
                                                                                    <InsertCrIn
                                                                                        hide={show}
                                                                                        key={Arraydata.id}
                                                                                        id={Arraydata.id}
                                                                                        trid={id}
                                                                                        crin={crin}
                                                                                        crintxt={crintxt}
                                                                                        date={date}
                                                                                        dailyCrInTrans={dailyCrInTrans}
                                                                                        // oPCash={accountStatus[0].opcash}
                                                                                        updateDashboard={(e) => updateDashboard()} />)
                                                                            }) ||
                                                                            <Tooltip title={crintxt} followCursor>
                                                                                {
                                                                                    (crin === 0 && '0') ||
                                                                                    <Badge color="warning" variant="dot">{crin}</Badge>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="right">{cashin}</TableCell>

                                                                    <TableCell align="right" className='total' >{(loginuserDetailId <= 3 && TotalIn) || ''}</TableCell>
                                                                    <TableCell align="right" className={(Adjest < 0 && 'cellred') || (Adjest > 250 && 'cellblu') || 'cellNor'}>
                                                                        {loginuserDetailId <= 5 && Adjest || ''}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {
                                                                            // pur
                                                                        }
                                                                        {(loginuserDetailId <= 3 || index >= filteredUsers.length - 1) &&
                                                                            DailyAccount.map((Arraydata) => {
                                                                                return (
                                                                                    <InsertPurch
                                                                                        hide={show}
                                                                                        key={Arraydata.id}
                                                                                        id={Arraydata.id}
                                                                                        trid={id}
                                                                                        pur={pur}
                                                                                        purchasetxt={purchasetxt}
                                                                                        date={date}
                                                                                        dailyPurchTrans={dailyPurchTrans}
                                                                                        // oPCash={accountStatus[0].opcash}
                                                                                        updateDashboard={(e) => updateDashboard()}

                                                                                    />)
                                                                            }) ||
                                                                            <Tooltip title={purchasetxt} followCursor>
                                                                                {
                                                                                    (pur === 0 && '0') ||
                                                                                    <Badge color="warning" variant="dot">{pur}</Badge>
                                                                                }
                                                                            </Tooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="right">{card}</TableCell>
                                                                    <TableCell align="right">{gpay}</TableCell>
                                                                    <TableCell align="right">{oldbill}</TableCell>
                                                                    <TableCell align="right">
                                                                        {
                                                                            // Adv Used
                                                                        }

                                                                        {(loginuserDetailId <= 3 || index >= filteredUsers.length - 1) &&
                                                                            DailyAccount.map((Arraydata) => {
                                                                                return (
                                                                                    <InsertAdvUsed
                                                                                        hide={show}
                                                                                        key={Arraydata.id}
                                                                                        id={Arraydata.id}
                                                                                        trid={id}
                                                                                        advused={advused}
                                                                                        advanceusedtxt={advanceusedtxt}
                                                                                        date={date}
                                                                                        dailyAdvUsTrans={dailyAdvUsTrans}
                                                                                        // oPCash={accountStatus[0].opcash}
                                                                                        updateDashboard={(e) => updateDashboard()}

                                                                                    />)
                                                                            }) ||
                                                                            <Tooltip title={advanceusedtxt} followCursor>
                                                                                {
                                                                                    (advused === 0 && '0') ||
                                                                                    <Badge color="warning" variant="dot">{advused}</Badge>
                                                                                }
                                                                            </Tooltip>
                                                                        }

                                                                    </TableCell>
                                                                    <TableCell align="right">{exp}</TableCell>
                                                                    <TableCell align="right">
                                                                        {
                                                                            // Cr Out
                                                                        }
                                                                        {(loginuserDetailId <= 3 || index >= filteredUsers.length - 1) &&
                                                                            DailyAccount.map((Arraydata) => {
                                                                                return (
                                                                                    <InsertCrOut
                                                                                        hide={show}
                                                                                        key={Arraydata.id}
                                                                                        id={Arraydata.id}
                                                                                        trid={id}
                                                                                        crout={crout}
                                                                                        creditouttxt={creditouttxt}
                                                                                        date={date}
                                                                                        dailyCrOutTrans={dailyCrOutTrans}
                                                                                        // oPCash={accountStatus[0].opcash}
                                                                                        updateDashboard={(e) => updateDashboard()}

                                                                                    />)
                                                                            }) ||
                                                                            <Tooltip title={creditouttxt} followCursor>
                                                                                {
                                                                                    (crout === 0 && '0') ||
                                                                                    <Badge color="warning" variant="dot">{crout}</Badge>
                                                                                }
                                                                            </Tooltip>
                                                                        }

                                                                    </TableCell>
                                                                    <TableCell align="right">{take}</TableCell>
                                                                    <TableCell align="right">{cashbox}</TableCell>
                                                                    <TableCell align="right" className='total'>{loginuserDetailId <= 3 && TotalOut || ''}</TableCell>

                                                                    <TableCell component="th" scope="row" padding="none">
                                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                                            {<Tooltip title={shorten} followCursor>
                                                                                {
                                                                                    <Avatar alt={name} src={avatarUrl} />
                                                                                }
                                                                            </Tooltip>}
                                                                        </Stack>
                                                                    </TableCell>


                                                                    <TableCell component="th" scope="row" padding="none">
                                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                                            <Tooltip title={(status === 0 && 'Pending') || (status === 2 && 'Edited') || (status === 1 && 'Ok')} followCursor>
                                                                                {
                                                                                    <Label name="ed" color={(status === 1 && 'success') || (status === 2 && 'info') || 'error'}>{
                                                                                        // sentenceCase(status)
                                                                                        (status === 0 && 'P') || (status === 2 && 'Ed') || (status === 1 && 'Ok')
                                                                                    }</Label>
                                                                                }
                                                                            </Tooltip>
                                                                            {(loginuserDetailId <= 3 && index >= filteredUsers.length - 20) &&
                                                                                DailyAccount.map((Arraydata) => {

                                                                                    return (

                                                                                        <EditAccounts

                                                                                            // shiftoPCash={accountStatus[0].shiftopcash}
                                                                                            hide={show}
                                                                                            key={id}
                                                                                            id={id}
                                                                                            trid={id}
                                                                                            date={date}
                                                                                            oPCash={opcash}
                                                                                            sales={sales}
                                                                                            nonbill={nonbill}
                                                                                            lastBillNo={lastbillno}
                                                                                            advance={advance}
                                                                                            crin={crin}
                                                                                            cashin={cashin}
                                                                                            // TotalIn={TotalIn}
                                                                                            // Adjest={Adjest}
                                                                                            pur={pur}
                                                                                            card={card}
                                                                                            gpay={gpay}
                                                                                            oldbill={oldbill}
                                                                                            advused={advused}
                                                                                            exp={exp}
                                                                                            crout={crout}
                                                                                            take={take}
                                                                                            cashbox={cashbox}
                                                                                            // TotalOut={TotalOut}
                                                                                            // name={name} 
                                                                                            userDetailId={loginuserDetailId}
                                                                                            // crintxt={crintxt} advancetxt={advancetxt} purchasetxt={purchasetxt} advanceusedtxt={advanceusedtxt} creditouttxt={creditouttxt}
                                                                                            updateDashboard={(e) => updateDashboard()}

                                                                                        />
                                                                                    );

                                                                                })

                                                                                || ''}
                                                                        </Stack>
                                                                    </TableCell>
                                                                </TableRow>

                                                            );
                                                        })}
                                                        {loginuserDetailId <= 3 &&
                                                            <TableRow >
                                                                <TableCell className='tot' colSpan={2}>
                                                                    Total
                                                                </TableCell>
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].monthlyTotalSales}
                                                                </TableCell>
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].nonBillSales}
                                                                </TableCell>
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].advance_Rec}
                                                                </TableCell>
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].cr_in}
                                                                </TableCell>
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].totalCashIn}
                                                                </TableCell>
                                                                <TableCell className='tot' />
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].adjested}
                                                                </TableCell>
                                                                <TableCell className='tot' colSpan={3} />
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].oldBillSales}
                                                                </TableCell>
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].advance_Used}
                                                                </TableCell>
                                                                <TableCell className='tot' />
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].cr_out}
                                                                </TableCell>
                                                                <TableCell align="right" className='tot'>
                                                                    {accountStatus[0].monthlyTake}
                                                                </TableCell>

                                                                <TableCell colSpan={4} className='tot' />

                                                            </TableRow> || ''
                                                        }
                                                        {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )}

                                                    </TableBody>
                                                )}
                                                {isNotFound && (
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                                <Paper
                                                                    sx={{
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    <Typography variant="h6" paragraph>
                                                                        Not found
                                                                    </Typography>

                                                                    <Typography variant="body2">
                                                                        No results found for &nbsp;
                                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                                        <br /> Try checking for typos or using complete words.
                                                                    </Typography>
                                                                </Paper>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                )}
                                            </Table>

                                        </TableContainer>
                                    </Paper>
                                </Scrollbar>

                                <TablePagination
                                    rowsPerPageOptions={[10, 30, 65]}
                                    component="div"
                                    count={account.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />

                            </Card>

                        </Container>
                        {loginuserDetailId === 1 &&
                            <Container>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom>
                                        100 Days Accounts
                                    </Typography>
                                </Stack>
                                <Card>
                                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                                    <Scrollbar>
                                        <Paper style={{ overflow: 'auto', width: '100%' }}>
                                            <TableContainer sx={{ minWidth: 800 }}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <UserListHeadNNP
                                                        order={order}
                                                        orderBy={orderBy}
                                                        // headLabel={TABLE_HEAD}
                                                        headLabel={ACCOINT_TABLE}
                                                        rowCount={account.length}
                                                        numSelected={selected.length}
                                                        onRequestSort={handleRequestSort}
                                                        onSelectAllClick={handleSelectAllClick}
                                                    />
                                                    <TableBody>

                                                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                            const { id, date, opcash, sales, nonbill, advance, crin, cashin, totalIn, adjest
                                                                , pur, card, gpay, oldbill, advused, exp, crout, take, cashbox, totalOut, name, status
                                                                , avatarUrl, crintxt, advancetxt, purchasetxt, advanceusedtxt, creditouttxt, userDetailId
                                                                , dailyAdvTrans, dailyCrInTrans, dailyPurchTrans, dailyCrOutTrans, dailyAdvUsTrans } = row;

                                                            const shorten = name ? name.substring(0, 3) : '';

                                                            const selectedUser = selected.indexOf(name) !== -1;
                                                            // console.log('Table');
                                                            // console.log(Userrr[0].userPunchiN);
                                                            // console.log(index);
                                                            // console.log(dailyCrInTrans);
                                                            return (

                                                                <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>

                                                                    <TableCell align="left">
                                                                        {dayjs(date).format('DD-MMM')}
                                                                    </TableCell>
                                                                    <TableCell align="right">{opcash}</TableCell>
                                                                    <TableCell align="right">{sales}</TableCell>
                                                                    <TableCell align="right">{nonbill}</TableCell>
                                                                    <TableCell align="right">{
                                                                        <Tooltip title={advancetxt} followCursor>
                                                                            {
                                                                                (advance === 0 && '0') ||
                                                                                <Badge color="warning" variant="dot">{advance}</Badge>
                                                                            }
                                                                        </Tooltip>
                                                                    }</TableCell>
                                                                    <TableCell align="right">
                                                                        {<Tooltip title={crintxt} followCursor>
                                                                            {
                                                                                (crin === 0 && '0') ||
                                                                                <Badge color="warning" variant="dot">{crin}</Badge>
                                                                            }
                                                                        </Tooltip>}
                                                                    </TableCell>
                                                                    <TableCell align="right">{cashin}</TableCell>

                                                                    <TableCell align="right" className='total' >{(loginuserDetailId === 1 && totalIn) || ''}</TableCell>
                                                                    <TableCell align="right" className={(adjest < 0 && 'cellred') || 'cellNor'}>
                                                                        {loginuserDetailId === 1 && adjest || ''}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {
                                                                            <Tooltip title={purchasetxt} followCursor>
                                                                                {
                                                                                    (pur === 0 && '0') ||
                                                                                    <Badge color="warning" variant="dot">{pur}</Badge>
                                                                                }
                                                                            </Tooltip>
                                                                        }

                                                                    </TableCell>
                                                                    <TableCell align="right">{card}</TableCell>
                                                                    <TableCell align="right">{gpay}</TableCell>
                                                                    <TableCell align="right">{oldbill}</TableCell>
                                                                    <TableCell align="right">
                                                                        {
                                                                            <Tooltip title={advanceusedtxt} followCursor>
                                                                                {
                                                                                    (advused === 0 && '0') ||
                                                                                    <Badge color="warning" variant="dot">{advused}</Badge>
                                                                                }
                                                                            </Tooltip>
                                                                        }



                                                                    </TableCell>
                                                                    <TableCell align="right">{exp}</TableCell>
                                                                    <TableCell align="right">
                                                                        {
                                                                            <Tooltip title={creditouttxt} followCursor>
                                                                                {
                                                                                    (crout === 0 && '0') ||
                                                                                    <Badge color="warning" variant="dot">{crout}</Badge>
                                                                                }
                                                                            </Tooltip>
                                                                        }


                                                                    </TableCell>
                                                                    <TableCell align="right">{take}</TableCell>
                                                                    <TableCell align="right">{cashbox}</TableCell>
                                                                    <TableCell align="right" className='total'>{loginuserDetailId === 1 && totalOut || ''}</TableCell>

                                                                    <TableCell component="th" scope="row" padding="none">
                                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                                            {<Tooltip title={shorten} followCursor>
                                                                                {
                                                                                    <Avatar alt={name} src={avatarUrl} />
                                                                                }
                                                                            </Tooltip>}
                                                                        </Stack>
                                                                    </TableCell>


                                                                    <TableCell component="th" scope="row" padding="none">
                                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                                            <Tooltip title={(status === 0 && 'Pending') || (status === 2 && 'Edited') || (status === 1 && 'Ok')} followCursor>
                                                                                {
                                                                                    <Label name="ed" color={(status === 1 && 'success') || (status === 2 && 'info') || 'error'}>{
                                                                                        // sentenceCase(status)
                                                                                        (status === 0 && 'P') || (status === 2 && 'Ed') || (status === 1 && 'Ok')
                                                                                    }</Label>
                                                                                }
                                                                            </Tooltip>

                                                                        </Stack>
                                                                    </TableCell>
                                                                </TableRow>

                                                            );
                                                        })}

                                                        {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )}

                                                    </TableBody>

                                                    {isNotFound && (
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                                    <Paper
                                                                        sx={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography variant="h6" paragraph>
                                                                            Not found
                                                                        </Typography>

                                                                        <Typography variant="body2">
                                                                            No results found for &nbsp;
                                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                                            <br /> Try checking for typos or using complete words.
                                                                        </Typography>
                                                                    </Paper>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )}
                                                </Table>

                                            </TableContainer>
                                        </Paper>
                                    </Scrollbar>

                                    <TablePagination
                                        rowsPerPageOptions={[10, 30, 65]}
                                        component="div"
                                        count={account.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />

                                </Card>

                            </Container>
                            ||

                            ''
                        }

                        <Grid item xs={12} md={6} lg={8}>
                            <AppWebsiteVisits
                                title="Daily Sales & Purchase"
                                subheader={saleIncrease}
                                chartLabels={saleGrapfDate}
                                chartData={[
                                    {
                                        name: 'Purchase',
                                        type: 'column',
                                        fill: 'gradient',
                                        data: purchaseGrapf,
                                    },
                                    {
                                        name: 'Sales',
                                        type: 'column',
                                        fill: 'gradient',
                                        data: saleGrapf,
                                    },


                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <AppCurrentVisits
                                title="Current Visits"
                                chartData={[

                                    { label: '', value: 0 },
                                    { label: 'Sales', value: monTotSale },
                                    { label: 'Purchase', value: monTotPur },
                                    { label: '', value: 0 },

                                ]}
                                chartColors={[
                                    theme.palette.primary.main,
                                    theme.palette.info.main,
                                    theme.palette.error.main,
                                    theme.palette.warning.main,

                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={8}>
                            <AppWebsiteVisits
                                title="Daily Purchase & Payments"
                                subheader={saleIncrease}
                                chartLabels={saleGrapfDate}
                                chartData={[
                                    {
                                        name: 'Purchase',
                                        type: 'column',
                                        fill: 'gradient',
                                        data: purchaseGrapf,
                                    },
                                    {
                                        name: 'Payment',
                                        type: 'column',
                                        fill: 'gradient',
                                        data: paymentGrapf,
                                    },

                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <AppCurrentVisits
                                title="Current Visits"
                                chartData={[
                                    { label: '', value: 0 },
                                    { label: 'Purchase', value: monTotPur },
                                    { label: 'Payment', value: monTotPay },
                                    { label: '', value: 0 },

                                ]}
                                chartColors={[
                                    theme.palette.info.main,
                                    theme.palette.primary.main,
                                    theme.palette.warning.main,
                                    theme.palette.error.main,

                                ]}
                            />
                        </Grid>




                    </Grid>
                </Container>



            </div>
        </>
    );
}
