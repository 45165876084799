
import { useEffect, useState, useRef } from 'react';
import Alert from '@mui/material/Alert';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Tooltip,
  Badge,
  // Grid,
  // Card,
  Table,
  // Stack,
  Paper,
  // Avatar,
  Button,
  // Popover,
  // Checkbox,
  TableRow,
  // MenuItem,
  TableBody,
  TableCell,
  // Container,
  // Typography,
  // IconButton,
  TableContainer,
  // TablePagination,
  TextField,
  TableHead,
  TableFooter,
} from '@mui/material';
import { id } from 'date-fns/locale';
import Swal from 'sweetalert2';
import AlertPage from "../pages/AlertPage";
import "./InsertAdvancessPopup.css";

// components
import Iconify from '../components/iconify';
import { variables } from '../Variables';
import { DailyAccountUpdateData } from '../model/DailyAccountModel';
import { InsertDailyAdvUs } from '../components/api/DailyAccountApiService';

function InsertAdvUsedPopup(props) {

  const navigate = useNavigate();
  const current = new Date(props.date);
  // const [date, setdate] = useState(props.date);
  const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


  const [trid, settrid] = useState(props.trid);

  const [advused, setadvused] = useState(0);

  const [advanceusedtxt, setadvanceusedtxt] = useState('');


  const [SaveStatus, setSaveStatus] = useState(0);
  const [show, setShow] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Resetcontrol = (e) => {
    e.current.style.border = "none";
  }




  const insertToDB = async (SaveStatus) => {
    const response = {
      InsertDate: current,
      TRAN_ID: props.trid,
      Amount: advused,
      GivenBy: advanceusedtxt,
    };
    const data = DailyAccountUpdateData(response);
    const res = await InsertDailyAdvUs(data);
    const result = res.data;
    if (Number(result.Status) === 100) {
      handleCloseModal(false);
      alert(result.StatusMessage);
      ClearAccountsData();
      props.updateDashboard(props.id);
    }
    else if (Number(result.Status) === -100) {
      handleCloseModal(false);
      alert('(-ve) acount. \nInserted Successfully!');
      ClearAccountsData();
      props.updateDashboard(props.id);
    }
    else if (Number(result.Status) === 200) {
      handleCloseModal(false);
      alert('(+ve) acount. \nInserted Successfully!');
      ClearAccountsData();
      props.updateDashboard(props.id);
    }
    else {
      Swal.fire({
        title: 'Are you sure?',
        text: "Save any way on -ve or +ve?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Save it!'
      }).then((result) => {
        if (result.isConfirmed) {
          InsertAdvUsedssData(1);
        }
      });
    }

    // Save it!
    // console.log(props.dailyPurchTrans.split('\n').map(str => <p>{str}</p>));
    // fetch(`${variables.API_URL}DailyAccounts/InsertDailyAdvUs`, {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     InsertDate: current,
    //     TRAN_ID: props.trid,
    //     Amount: advused,
    //     GivenBy: advanceusedtxt,
    //   })
    // })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then((result) => {

    //     // console.log(result);
    //     if (result.interactID === 100) {
    //       handleCloseModal(false);
    //       alert(result.message);
    //       ClearAccountsData();
    //       props.updateDashboard(props.id);
    //     }
    //     else if (result.interactID === -100) {
    //       handleCloseModal(false);
    //       alert('(-ve) acount. \nInserted Successfully!');
    //       ClearAccountsData();
    //       props.updateDashboard(props.id);
    //     }
    //     else if (result.interactID === 200) {
    //       handleCloseModal(false);
    //       alert('(+ve) acount. \nInserted Successfully!');
    //       ClearAccountsData();
    //       props.updateDashboard(props.id);
    //     }
    //     else {

    //       if (window.confirm(result.message)) {
    //         // Save it!
    //         console.log('Save any way on -ve or +ve');
    //         InsertAdvUsedssData(1);
    //       } else {
    //         // Do nothing!
    //         console.log('Opt to crosscheck');
    //       }
    //       // alert(result.message);
    //       console.log('');
    //     }
    //     // alert('Account Saved!');
    //     //* alert(props.id);

    //   }, (error) => {
    //     alert(error);
    //   });

  }
  const InsertAdvUsedssData = (SaveStatus) => {
    setShow(true);
    if ((advused > 0 && advanceusedtxt.trim() !== "")) {
      if (SaveStatus === 1) {
        insertToDB(SaveStatus);
      }
      else {
        Swal.fire({
          title: 'Are you sure?',
          text: "Do you want to save?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Save it!'
        }).then((result) => {
          if (result.isConfirmed) {
            insertToDB(0);
          }
        });
      }
    }
    else {
      alert("\"Both fields are requered! ")
    }





  };
  const ClearAccountsData = () => {
    settrid(0);

    setadvused(0);

    setadvanceusedtxt('');

  };

  const handleCloseModal = (st) => {
    setShow(st)
  }

  return (
    <>
      {props.hide ? (
        <Tooltip title={props.advanceusedtxt} followCursor>
          {
            (props.advused === 0 && '0') ||
            <Badge color="warning" variant="dot">{props.advused}</Badge>
          }
        </Tooltip>

      ) : (
        <Button size="small" className='sml' onClick={handleShow} startIcon={<Iconify icon="eva:plus-fill" />}>
          {<Tooltip title={props.advanceusedtxt} followCursor>
            {
              (props.advused === 0 && '0') ||
              <Badge color="warning" variant="dot">{props.advused}</Badge>
            }
          </Tooltip>}
        </Button>

      )}
      {/*
      <button onClick={handleShow}  className="css-12vebo6-MuiButtonBase-root-MuiButton-root block m-2 bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
        Update
  </button> */}


      <Modal style={{ zIndex: 1100 }}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adv Used on -[{datee}]</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id='InserAccountsPopup' name='Edwit' onSubmit={(e) => {
            e.preventDefault();
            // console.log('Form Submited');
            InsertAdvUsedssData(0);
            // console.log(props.id, name, role);

            // console.log(' after');

          }}>

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead />
                <TableBody>
                  <TableRow>



                    <TableCell component="th" scope="row">
                      <TextField variant="standard" type="number" size="small" name="adv" label="Adv Used Cash ₹ "
                        value={advused}
                        onChange={(e) => { setadvused(e.target.value) }}


                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField variant="standard"
                        size="small" fullWidth name="advd" label="Adv Used Desc.."
                        onChange={(e) => { setadvanceusedtxt(e.target.value) }}
                        value={advanceusedtxt}
                      />
                    </TableCell>

                  </TableRow>

                </TableBody>
                <TableFooter />
              </Table>
            </TableContainer>

            <TextField variant="standard" multiline fullWidth size="small" label="Adv Used Cash ₹ "
              value={props.dailyAdvUsTrans}
            />

          </form>
        </Modal.Body>
        <Modal.Footer>

          <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
            Close
          </button>

          <button onClick={handleClose} form='InserAccountsPopup' className="bt-green css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
            Add AdvUs
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default InsertAdvUsedPopup;