
import { useState, useRef, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Modal from 'react-bootstrap/Modal';


// ** Date Formating
import dayjs from "dayjs";

// @mui
import {
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TextField,
  TableHead,
  TableFooter,
  Tooltip,
} from '@mui/material';
import Swal from 'sweetalert2';
import { id } from 'date-fns/locale';
import { UpdateDailyAccount, UpdateDailyAccountStatus } from '../components/api/DailyAccountApiService';
import AlertPage from "../pages/AlertPage";
import "./EditAccountsPopup.css";
import { variables } from '../Variables';
// components
import Iconify from '../components/iconify';
import DailyAccountDetailsPopup from './DailyAccountDetailsPopup';


function EditAccountsPopup(props) {
  const current = new Date(props.date);
  // const [date, setdate] = useState(props.date);
  const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  // const [userDetailId, seTuserDetailId] = useState(props.userDetailId);
  // const [trid, seTtrid] = useState(props.trid);
  const [OPCash, setOPCash] = useState(props.oPCash);
  // const [ShiftoPCash, setShiftoPCash] = useState(props.shiftoPCash);
  const [TotaBillSales, setTotaBillSales] = useState(props.sales);
  const [LastBillNo, setLastBillNo] = useState(props.lastBillNo);
  const [TotaNonBillSales, setTotaNonBillSales] = useState(props.nonbill);
  // const [CreditIN, setCreditIN] = useState(props.crin);
  // const [Advance, setAdvance] = useState(props.advance);
  const [CashIn, setCashIn] = useState(props.cashin);
  // const [PurchaseCashPayment, setPurchaseCashPayment] = useState(props.pur);  
  const [GPay, setGPay] = useState(props.gpay);
  const [CardPay, setCardPay] = useState(props.card);
  const [Expence, setExpence] = useState(props.exp);
  // const [CreditOut, setCreditOut] = useState(props.crout);
  const [Take, setTake] = useState(props.take);
  const [CashClose, setCashClose] = useState(props.cashbox);
  const [CashText, setCashText] = useState('');
  const [OldBills, setOldBills] = useState(props.oldbill);


  const [SaveStatus, setSaveStatus] = useState(0);
  const [show, setShow] = useState(false);
  const [statuchk, setstatuchk] = useState(0);

  const refAdvanceUsedTxt = useRef();
  const refCreditOutTxt = useRef();
  const refCreditINTxt = useRef();
  const refAdvanceTxt = useRef();
  // const refAdvanceUsedTxt = useRef();
  const refLastBillNo = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setOPCash(props.oPCash);
    setTotaBillSales(props.sales);
    setLastBillNo(props.lastBillNo);
    setTotaNonBillSales(props.nonbill);
    setCashIn(props.cashin);
    setGPay(props.gpay);
    setCardPay(props.card);
    setExpence(props.exp);
    setTake(props.take);
    setCashClose(props.cashbox);
    setOldBills(props.oldbill);
  }, [props]);

  const clearState = () => {
    setOPCash(0);
    setTotaBillSales(0);
    setLastBillNo('');
    setTotaNonBillSales(0);
    setCashIn(0);
    setGPay(0);
    setCardPay(0);
    setExpence(0);
    setTake(0);
    setCashClose(0);
    setCashText('');
    setOldBills(0);
  };

  const Resetcontrol = (e) => {
    e.current.style.border = "none";
  }

  const UpdateStatus = async (statid) => {

    const response = {
      "UserDetailId": Number(props.trid),
      "PunchType": statid
    }
    setstatuchk(1);
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to Update as "${(statid === 1 && 'Ok' || (statid === 0 && 'Pending' || ''))}" ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Save it!',
      customClass: {
        container: 'swal2-container'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await UpdateDailyAccountStatus(response);
        const res = result.data;
        if (res.Id > 0) {
          handleCloseModal(false);
          alert(res.StatusMessage);
          ClearAccountsData();
          props.updateDashboard(props.id);
        }
        else {
          alert(res.StatusMessage);
        }
      }
    });

    // alert(props.trid);

    // if (window.confirm(`Do you want to Update as "${(statid === 1 && 'Ok' || (statid === 0 && 'Pending' || ''))}" ?`)) {
    //   fetch(`${variables.API_URL}DailyAccounts/UpdateAccountStatus`, {
    //     method: 'POST',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //       UserDetailId: props.trid,
    //       // TRAN_DATE: current,
    //       PunchType: statid,
    //     })
    //   })

    //     .then(res => {
    //       return res.json();
    //     })
    //     .then((result) => {
    //       // alert(result);
    //       // console.log(result);
    //       if (result.interactID === 100) {
    //         handleCloseModal(false);
    //         alert(result.message);
    //         ClearAccountsData();
    //         props.updateDashboard(props.id);
    //       }
    //       else {

    //         alert(result.message);
    //         console.log(result.message);
    //       }
    //       // alert(result.message);
    //       // alert(props.id);

    //     }, (error) => {
    //       alert(error);
    //     });

    //   // console.log('GetUserDetails');
    //   // console.log(Userrr);
    // }
    // else {
    //   // Do nothing!
    //   console.log('Canceled UpdateStatus');
    // }

  }

  const UpdateToDB = async (logid) => {
    const response = {
      TRAN_ID: props.trid,
      // TRAN_DATE: current,
      TRAN_OPCash: Number(OPCash),
      TRAN_TotaBillSales: Number(TotaBillSales),
      TRAN_TotaNonBillSales: Number(TotaNonBillSales),
      TRAN_LastBillNo: LastBillNo,
      TRAN_CreditIN: Number(props.crin),
      TRAN_Advance: Number(props.advance),
      TRAN_CashIn: CashIn,
      TRAN_PurchaseCashPayment: Number(props.pur),
      TRAN_GPay: Number(GPay),
      TRAN_CardPay: Number(CardPay),
      TRAN_OldBills: Number(OldBills),
      TRAN_AdvUsed: Number(props.advused),
      TRAN_Expence: Number(Expence),
      TRAN_CreditOut: Number(props.crout),
      TRAN_Take: Number(Take),
      TRAN_CashClose: CashClose,
      UserDetailId: props.userDetailId,
      InsertAnyWay: logid,
    };
    const result = await UpdateDailyAccount(response);
    const res = result.data;
    if (res.Id > 0) {
      handleCloseModal(false);
      alert(res.StatusMessage);
      ClearAccountsData();
      props.updateDashboard();
    }
    else {
      Swal.fire({
        title: 'Are you sure?',
        text: res.StatusMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Save it!',
        customClass: {
          container: 'swal2-container'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          UpdateAccountsData(1);
        }
      });
    }
    // fetch(`${variables.API_URL}DailyAccounts/UpdateAccounts`, {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     TRAN_ID: props.trid,
    //     // TRAN_DATE: current,
    //     TRAN_OPCash: OPCash,
    //     TRAN_TotaBillSales: TotaBillSales,
    //     TRAN_TotaNonBillSales: TotaNonBillSales,
    //     TRAN_LastBillNo: LastBillNo,
    //     TRAN_CreditIN: props.crin,
    //     TRAN_Advance: props.advance,
    //     TRAN_CashIn: CashIn,
    //     TRAN_PurchaseCashPayment: props.pur,
    //     TRAN_GPay: GPay,
    //     TRAN_CardPay: CardPay,
    //     TRAN_OldBills: OldBills,
    //     TRAN_AdvUsed: props.advused,
    //     TRAN_Expence: Expence,
    //     TRAN_CreditOut: props.crout,
    //     TRAN_Take: Take,
    //     TRAN_CashClose: CashClose,
    //     UserDetailId: props.userDetailId,
    //     InsertAnyWay: logid,
    //   })
    // })
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then((result) => {
    //     if (result.interactID === 100) {
    //       handleCloseModal(false);
    //       alert(result.message);
    //       ClearAccountsData();
    //       props.updateDashboard(props.id);
    //     }
    //     else {
    //       if (window.confirm(result.message)) {
    //         // Save it!
    //         console.log('Save any way on -ve or +ve');
    //         UpdateAccountsData(1);
    //       } else {
    //         // Do nothing!
    //         console.log('Opt to crosscheck');
    //       }
    //       // alert(result.message);
    //       console.log('');
    //     }
    //     // alert('Account Saved!');
    //     // alert(props.id);

    //   }, (error) => {
    //     alert(error);
    //   });
  }

  const UpdateAccountsData = (SaveStatus) => {
    setShow(true);
    // console.log(AdvUsed);
    // console.log(AdvanceUsedTxt.trim());

    // if (AdvUsed > 0 && AdvanceUsedTxt.trim() === "") {
    //   // refAdvanceUsedTxt.current.focus();
    //   refAdvanceUsedTxt.current.style.border = "2px solid #fd9797";

    //   alert("\"Please enter \"Advance-Used description\"! ")
    // }
    // else if (CreditOut > 0 && CreditOutTxt.trim() === "") {
    //   // refAdvanceUsedTxt.current.focus();
    //   refCreditOutTxt.current.style.border = "2px solid #fd9797";
    //   alert("\"Please enter \"Credit-Out's description\"! ")
    // }
    // else if (CreditIN > 0 && CreditINTxt.trim() === "") {
    //   // refAdvanceUsedTxt.current.focus();
    //   refCreditINTxt.current.style.border = "2px solid #fd9797";
    //   alert("\"Please enter \"Credit-In's used description\"! ")
    // }
    // else if (Advance > 0 && AdvanceTxt.trim() === "") {
    //   // refAdvanceUsedTxt.current.focus();
    //   refAdvanceTxt.current.style.border = "2px solid #fd9797";
    //   alert("\"Please enter \"Advance's description\"! ")
    // }
    if (statuchk === 0) {
      if (LastBillNo === 0 || LastBillNo.trim() === "") {
        refLastBillNo.current.focus();
        refLastBillNo.current.style.border = "2px solid #fd9797";
        alert("\"Please enter \"Last bill number\"! ")
      }
      else if (TotaBillSales <= 0 || CashClose <= 0) {
        alert("\"Total sales\" and \"Closing cash\" cannot be zero! ")
      }
      else {
        console.log("Saving");
        console.log(SaveStatus);
        if (SaveStatus === 1) {
          UpdateToDB(SaveStatus);
        }
        else {
          Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to save?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Save it!'
          }).then((result) => {
            if (result.isConfirmed) {
              UpdateToDB(0);
            }
          });
        }
      }
    }
    else {
      setstatuchk(0);
    }
  };

  const ClearAccountsData = () => {
    setTotaBillSales(0);
    setOPCash(0);
    setTotaBillSales(0);
    setTotaNonBillSales(0);
    setCashIn(0);
    setGPay(0);
    setCardPay(0);
    setExpence(0);
    // setCreditOut(0);
    setTake(0);
    setCashClose(0);
    setCashText('');
    setOldBills(0);

  };

  const handleCloseModal = (st) => {
    setShow(st);
  }

  return (
    <>
      {props.hide ? (
        ''
      ) : (
        <>
          <Tooltip title="Edit Accounts">
          <IconButton className='dot' size="medium" color="inherit" onClick={handleShow}>
            <Iconify icon={'eva:edit-fill'} style={{ fontSize: '24px' }}/>
          </IconButton>
          </Tooltip>
        </>
      )}
      {/*
      <button onClick={handleShow}  className="css-12vebo6-MuiButtonBase-root-MuiButton-root block m-2 bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
        Update
  </button> */}


      <Modal style={{ zIndex: 1100 }}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update [{datee}] 's Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id='InserAccountsPopup' name='Edwit' onSubmit={(e) => {
            e.preventDefault();
            // console.log('Form Submited');
            UpdateAccountsData(0);
            // console.log(props.id, name, role);

            // console.log(' after');

          }}>

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead />
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {/* <TextField className='red' variant="standard" type="number" size="small" name="opCash" label="Opening Cash ₹ " 
                        value={props.oPCash}
                        onChange={(e) => { setOPCash(e.target.value)}}
                      /> */}

                      <TextField variant="standard" className='red' type="number" size="small" name="opCash" label="Opening Cash ₹ "
                        value={OPCash}
                        onChange={(e) => { setOPCash(e.target.value) }} />

                      {/* <TextField variant="standard" className='hf red' type="number" size="small" name="ShiftopCash" label="Shift Opening Cash ₹ "
                        value={props.shiftoPCash}
                        onChange={(e) => { setShiftoPCash(e.target.value) }} /> */}

                    </TableCell>
                    <TableCell align="right">
                      <TextField variant="standard" type="number" size="small" name="cardSwipe" label="Card swipe amount ₹ "
                        value={CardPay}
                        onChange={(e) => { setCardPay(e.target.value) }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">


                      <TextField variant="standard" className='hf' type="number" size="small" name="Bill" label="Total Sales ₹ "
                        value={TotaBillSales}
                        onChange={(e) => { setTotaBillSales(e.target.value) }} />
                      <TextField variant="standard" className='hf' size="small" name="LastBill" label="Last Bill No "
                        value={LastBillNo}
                        ref={refLastBillNo}
                        onFocus={(e) => { Resetcontrol(refLastBillNo) }}
                        onChange={(e) => { setLastBillNo(e.target.value) }} />

                    </TableCell>
                    <TableCell align="right"><TextField variant="standard" type="number" size="small" name="gpay" label="GPay Amount ₹ "
                      value={GPay}
                      onChange={(e) => { setGPay(e.target.value) }}
                    /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row"><TextField variant="standard" type="number" size="small" name="nonBill" label="Non Bills ₹ "
                      value={TotaNonBillSales}
                      onChange={(e) => { setTotaNonBillSales(e.target.value) }}
                    /></TableCell>
                    <TableCell align="right"><TextField variant="standard" type="number" size="small" name="oldBill" label="Old Bills ₹ "
                      value={OldBills}
                      onChange={(e) => { setOldBills(e.target.value) }}
                    /></TableCell>


                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row"><TextField variant="standard" type="number" size="small" name="cashIn" label="Cash In ₹ "
                      value={CashIn}
                      onChange={(e) => { setCashIn(e.target.value) }}
                    /></TableCell>
                    <TableCell align="right"><TextField variant="standard" type="number" size="small" name="exp" label="Shop Expencess ₹ "
                      value={Expence}
                      onChange={(e) => { setExpence(e.target.value) }}
                    /></TableCell>

                  </TableRow>

                  <TableRow>
                    <TableCell ><TextField variant="standard" type="number" size="small" small name="cashTake" label="Cash Taken ₹ "
                      value={Take}
                      onChange={(e) => { setTake(e.target.value) }}
                    /></TableCell>
                    <TableCell align="right"><TextField className='red' variant="standard" type="number" size="small" name="clsCash"
                      InputProps={{

                      }} label="Closing Cash ₹ "
                      value={CashClose}
                      onChange={(e) => { setCashClose(e.target.value) }}
                    /></TableCell>
                  </TableRow>

                </TableBody>
                <TableFooter />
              </Table>
            </TableContainer>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => UpdateStatus(0)} color='error' className="bt-red css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
            Pending
          </button>

          <button onClick={() => UpdateStatus(1)} form='InserAccountsPopup' className="bt-green css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
            Ok
          </button>

          <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
            Close
          </button>

          <button onClick={handleClose} form='InserAccountsPopup' className="bt-yellow css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default EditAccountsPopup;