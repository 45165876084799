import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import DashboardMDPage from './pages/DashboardMDPage';
import AdvanceView from './pages/AdvanceView';
import CreditsView from './pages/CreditsView';
import AccountEntryOf100Days from './pages/AccountEntryOf100Days';
import NonBillView from './pages/NonBillView';
import PendingMonthlyCustomers from './pages/PendingMonthlyCustomers';
import EmployeeAttendance from './pages/EmployeeAttendance';
import AdminLayout from './layouts/dashboard/AdminLayout';
import MRPProfits from './pages/MRPProfits';
// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  const [loginuserDetailId, setloginuserDetailId] = useState();
  const [shopId, setShopId] = useState();
  const [loginuserType, setloginuserType] = useState();


  const chkUserLogin = async () => {
    const b = await JSON.parse(localStorage.getItem('mYLog'));
    if (b === null) {
      navigate('/login', { replace: true });
    } else {
      setloginuserDetailId(JSON.parse(localStorage.getItem('mYLog')).UId);
      setShopId(JSON.parse(localStorage.getItem('mYLog')).shopId);
      setloginuserType(localStorage.getItem('UserTypeId'));
      // console.log(shopId)
    }
  };

  useEffect(() => {
    chkUserLogin();
  }, []);

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <AdminLayout />,
      children: [
        // { element: <Navigate to={shopId === 'MD' ? '/dashboard/md' : '/dashboard/app'} />, index: true },
        { element: <Navigate to={'/dashboard/app'} />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'md', element: <DashboardMDPage /> },
        { path: 'adv', element: <AdvanceView /> },
        { path: 'mrppro', element: <MRPProfits /> },
        { path: 'cr', element: <CreditsView /> },
        { path: 'ac100', element: <AccountEntryOf100Days /> },
        { path: 'nonbill', element: <NonBillView /> },
        { path: 'pndmcust', element: <PendingMonthlyCustomers /> },
        { path: 'empatten', element: <EmployeeAttendance /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={loginuserDetailId === 1 && "/dashboard/md" || "/dashboard/app"} />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
