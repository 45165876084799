
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
    Card,
    Table,
    Paper,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TextField,
    TableHead,
    TableFooter,
    IconButton,
    Avatar,
    Tooltip,
    Stack,
} from '@mui/material';
import dayjs from "dayjs";
import { filter } from 'lodash';
import "./InsertAdvancessPopup.css";
import Iconify from '../components/iconify';
import { GetDailyAccountDetails, InsertCommitments } from '../components/api/DailyAccountApiService';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { UserListHeadNNP } from '../sections/@dashboard/user';
import Label from '../components/label';

const COMMITMENT_TABLE = [
    { id: 'date', label: 'Dt', alignRight: false },
    { id: 'opcash', label: 'Op Cash', alignRight: false },
    { id: 'sales', label: 'Sales', alignRight: false },
    { id: 'nonbill', label: 'Non Bill', alignRight: false },
    { id: 'advance', label: 'Advance', alignRight: false },
    { id: 'crin', label: 'CR In', alignRight: false },
    { id: 'cashin', label: 'Cash In', alignRight: false },
    { id: 'TotalIn', label: '', alignRight: false },
    { id: 'Adjest', label: '', alignRight: false },
    { id: 'pur', label: 'Purchase', alignRight: false },
    { id: 'card', label: 'Card', alignRight: false },
    { id: 'gpay', label: 'GPay', alignRight: false },
    { id: 'oldbill', label: 'Old Bill', alignRight: false },
    { id: 'advused', label: 'Adv: Used', alignRight: false },
    { id: 'exp', label: 'Exp  ', alignRight: false },
    { id: 'crout', label: 'CR Out', alignRight: false },
    { id: 'take', label: 'Take', alignRight: false },
    { id: 'cashbox', label: 'Cash Box', alignRight: false },
    { id: 'TotalOut', label: '', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
];

function DailyAccountDetailsPopup(props) {

    const [DailyAccount, setDailyAccount] = useState([
        {
          id: 1,
          oPCash: 0,
        },
      ]);
    const [commitOpen, setcommitOpen] = useState(0);
    const [commitClose, setcommitClose] = useState(0);
    const [commitCancel, setcommitCancel] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [loginuserDetailId, setLoginuserDetailId] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(65);
    const [show, setShow] = useState(false);
    const [userAccounts, setUserAccounts] = useState([{}]);
    const [filterName, setFilterName] = useState('');
    const filteredAccounts = applySortFilter(userAccounts, getComparator(order, orderBy), filterName);
    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true)
        fetchDailyAccountsDetailsData(id);
    };
    
    const handleCloseModal = (st) => {
        setShow(st)
    }

    const fetchDailyAccountsDetailsData = async (id) => {
        try {
            const res = await GetDailyAccountDetails(id, props.ShopId);
            if (res.data) {
                setUserAccounts(res.data);
            }
        }
        catch (error) {
            console.error('Error in fetchDailyAccountsDetailsData:', error);
        }
    };

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }



    return (
        <>
            <IconButton className='dot' size="medium" color="inherit" onClick={() => handleShow(props.id)}>
                <Iconify icon={'eva:eye-fill'} style={{ fontSize: '24px' }} />
            </IconButton>
            <Modal style={{ zIndex: 1100 }}
                show={show}
                onHide={handleClose}
                backdrop="static"
                className="custom-modal"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Daily Accounts Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id='InsertCommitmentPopup' name='Edwit' >
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead />
                                <TableBody />
                                <TableFooter />
                            </Table>
                        </TableContainer>
                        <Card>
                            <Scrollbar>
                                <Paper style={{ overflow: 'auto' }}>
                                    <TableContainer >
                                        <Table stickyHeader aria-label="sticky table">
                                            <UserListHeadNNP
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={COMMITMENT_TABLE}
                                                rowCount={userAccounts.length}
                                                numSelected={selected.length}
                                            />
                                            <TableBody>
                                                {filteredAccounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                    const { id, date, opcash, sales, nonbill, lastbillno, advance, crin, cashin, TotalIn, Adjest
                                                        , pur, card, gpay, oldbill, advused, exp, crout, take, cashbox, TotalOut, name, status
                                                        , avatarUrl, crintxt, advancetxt, purchasetxt, advanceusedtxt, creditouttxt, userDetailId
                                                        , dailyAdvTrans, dailyCrInTrans, dailyPurchTrans, dailyCrOutTrans, dailyAdvUsTrans } = row;

                                                    const shorten = name ? name.substring(0, 3) : '';


                                                    const lastdayclosingcash = filteredAccounts[(index > 0 && (index - 1) || index)].cashbox;
                                                    return (
                                                        <TableRow hover key={id} tabIndex={-1}>
                                                            <TableCell align="left">
                                                                {dayjs(date).format('DD-MMM')}
                                                            </TableCell>
                                                            <TableCell align="right" className={index > 2 && (lastdayclosingcash === opcash && 'c' || 'cellred') || 'c'}>{opcash}</TableCell>
                                                            <TableCell align="right">{sales}</TableCell>
                                                            <TableCell align="right">{nonbill}</TableCell>
                                                            <TableCell align="right">{advance}</TableCell>
                                                            <TableCell align="right">{crin}</TableCell>
                                                            <TableCell align="right">{cashin}</TableCell>
                                                            <TableCell align="right" className='total' >{TotalIn}</TableCell>
                                                            <TableCell align="right" className={(Adjest < 0 && 'cellred') || (Adjest > 250 && 'cellblu') || 'cellNor'}>{ Adjest}</TableCell>
                                                            <TableCell align="right">{pur}</TableCell>
                                                            <TableCell align="right">{card}</TableCell>
                                                            <TableCell align="right">{gpay}</TableCell>
                                                            <TableCell align="right">{oldbill}</TableCell>
                                                            <TableCell align="right">{advused}</TableCell>
                                                            <TableCell align="right">{exp}</TableCell>
                                                            <TableCell align="right">{crout}</TableCell>
                                                            <TableCell align="right">{take}</TableCell>
                                                            <TableCell align="right">{cashbox}</TableCell>
                                                            <TableCell align="right" className='total'>{TotalOut}</TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                                    {<Tooltip title={shorten} followCursor>
                                                                        {
                                                                            <Avatar alt={name} src={avatarUrl} />
                                                                        }
                                                                    </Tooltip>}
                                                                </Stack>
                                                            </TableCell>

                                                            <TableCell component="th" scope="row" padding="none">
                                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                                    <Tooltip title={(status === 0 && 'Pending') || (status === 2 && 'Edited') || (status === 1 && 'Ok')} followCursor>
                                                                            <Label name="ed" color={(status === 1 && 'success') || (status === 2 && 'info') || 'error'}>{
                                                                                (status === 0 && 'P') || (status === 2 && 'Ed') || (status === 1 && 'Ok')
                                                                            }</Label>
                                                                    </Tooltip>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Scrollbar>
                        </Card>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default DailyAccountDetailsPopup;