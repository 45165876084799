
import { useEffect, useState, useRef } from 'react';
import Alert from '@mui/material/Alert';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

// ** Date Formating
import dayjs from "dayjs";
// @mui
import {
    Tooltip,
    Badge,
    // Grid,
    // Card,
    Table,
    // Stack,
    Paper,
    // Avatar,
    Button,
    // Popover,
    // Checkbox,
    TableRow,
    // MenuItem,
    TableBody,
    TableCell,
    // Container,
    // Typography,
    // IconButton,
    TableContainer,
    // TablePagination,
    TextField,
    TableHead,
    TableFooter,
} from '@mui/material';
import { id } from 'date-fns/locale';
import Swal from 'sweetalert2';
import AlertPage from "../pages/AlertPage";
import "./InsertAdvancessPopup.css";

// components
import Iconify from '../components/iconify';
import { variables } from '../Variables';
import { saveDailyCommitmentsData } from '../model/DailyAccountModel';
import { InsertCommitments } from '../components/api/DailyAccountApiService';
// import InsertCommitments from 'src/pages/InsertCommitments';

function InsertCommitmentsPopup(props) {

    const navigate = useNavigate();
    const current = new Date();
    // const [date, setdate] = useState(props.date);
    const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;


    // const [trid, settrid] = useState(props.trid);

    // const [advused, setadvused] = useState(0);

    // const [advanceusedtxt, setadvanceusedtxt] = useState('');

    const [commitOpen, setcommitOpen] = useState(0);
    const [commitClose, setcommitClose] = useState(0);
    const [commitCancel, setcommitCancel] = useState(0);

    const [SaveStatus, setSaveStatus] = useState(0);
    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const Resetcontrol = (e) => {
        e.current.style.border = "none";
    }




    const insertToDB = async (SaveStatus) => {
        const payload = {
            TRAN_CommitDate: current,
            TRAN_CommitOpen: commitOpen,
            TRAN_CommitClosed: commitClose,
            TRAN_CommitCanceled: commitCancel,
            TRAN_ShopID: props.shopID
        };
        const data = saveDailyCommitmentsData(payload);
        const res = await InsertCommitments(data);
        const result = res.data;
        if (result.Id > 0) {
            handleCloseModal(false);
            alert(result.StatusMessage);
            ClearAccountsData();
            props.updateDashboard();
        }
        else {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Save any way on -ve or +ve',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Save it!',
                customClass: {
                    container: 'swal2-container'
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    InsertCommits(1);
                }
            });
        }

        // fetch(`${variables.API_URL}DailyAccounts/InsertCommitments`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         // TRAN_CommitID: current,
        //         // TRAN_CommitDate: '',
        //         TRAN_CommitOpen: commitOpen,
        //         TRAN_CommitClosed: commitClose,
        //         TRAN_CommitCanceled: commitCancel,
        //         TRAN_ShopID: props.shopID
        //     })
        // })
        //     .then(res => {
        //         return res.json();
        //     })
        //     .then((result) => {
        //         // console.log(result);
        //         if (result.interactID === 100) {
        //             handleCloseModal(false);
        //             alert(result.message);
        //             ClearAccountsData();
        //             props.updateDashboard();
        //         }
        //         else if (result.interactID === -100) {
        //             handleCloseModal(false);
        //             alert('(-ve) acount. \nInserted Successfully!');
        //             ClearAccountsData();
        //             props.updateDashboard();
        //         }
        //         else if (result.interactID === 200) {
        //             handleCloseModal(false);
        //             alert('(+ve) acount. \nInserted Successfully!');
        //             ClearAccountsData();
        //             props.updateDashboard();
        //         }
        //         else {
        //             if (window.confirm(result.message)) {
        //                 // Save it!
        //                 console.log('Save any way on -ve or +ve');
        //                 InsertCommits(1);
        //             } else {
        //                 // Do nothing!
        //                 console.log('Opt to crosscheck');
        //             }
        //             // alert(result.message);
        //             console.log('');
        //         }
        //         // alert('Account Saved!');
        //         //* alert(props.id);
        //     }, (error) => {
        //         alert(error);
        //     });
    }

    const InsertCommits = (SaveStatus) => {
        setShow(true);
        if ((commitOpen > 0 || commitClose > 0 || commitCancel > 0)) {
            if (SaveStatus === 1) {
                insertToDB(SaveStatus);
            }
            else {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "Do you want to save?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Save it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        insertToDB(0);
                    }
                });
            }
        }
        else {
            alert("\"Any one field is requered! ")
        }
    };
    
    const ClearAccountsData = () => {
        setcommitOpen(0);

        setcommitClose(0);

        setcommitCancel(0);

    };

    const handleCloseModal = (st) => {
        setShow(st)
    }

    return (
        <>

            <Button variant="outlined" onClick={handleShow} startIcon={<Iconify icon="eva:plus-fill" />}>
                Today Commitments ({props.opencommit}-{props.closecommit}-{props.cancelcommit})
            </Button>



            <Modal style={{ zIndex: 1100 }}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Customer Commits on {dayjs(datee).format('DD-MMM-YYYY')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id='InsertCommitmentsPopup' name='Edwit' onSubmit={(e) => {
                        e.preventDefault();
                        // console.log('Form Submited');
                        InsertCommits(0);
                        // console.log(props.id, name, role);

                        // console.log(' after');

                    }}>

                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead />
                                <TableBody>
                                    <TableRow>

                                        <TableCell component="th" scope="row">
                                            <TextField variant="standard" type="number" size="small" name="adv" label="Commitments "
                                                value={commitOpen}
                                                onChange={(e) => { setcommitOpen(e.target.value) }}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField variant="standard"
                                                size="small" fullWidth name="advd" label="Closed Commitments"
                                                onChange={(e) => { setcommitClose(e.target.value) }}
                                                value={commitClose}
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField variant="standard"
                                                size="small" fullWidth name="advd" label="Canceled Commitments"
                                                onChange={(e) => { setcommitCancel(e.target.value) }}
                                                value={commitCancel}
                                            />
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                                <TableFooter />
                            </Table>
                        </TableContainer>



                    </form>
                </Modal.Body>
                <Modal.Footer>

                    <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                        Close
                    </button>

                    <button onClick={handleClose} form='InsertCommitmentsPopup' className="bt-green css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                        Add AdvUs
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default InsertCommitmentsPopup;