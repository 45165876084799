
import ProgressBarPopup from "../Popups/ProgressBarPopup";


function FileProgressBar(props) {
  return (
     
      <ProgressBarPopup/>
      
  );
}

export default FileProgressBar;
