import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { userAuthenticate } from '../../../components/api/AuthenticationApiService';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------



export default function LoginForm() {
  const navigate = useNavigate();
  const [employee, setemployee] = useState({ Email: '', Password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async () => {
    const res = await userAuthenticate(employee.Email, employee.Password);
    const result = res.data;
    if (result.StatusCode === 200) {

      const serializedState = JSON.stringify(result.userDetails);
      localStorage.setItem('profileImage', result.ProfileImage);
      localStorage.setItem('userId', result.UserID);
      localStorage.setItem('displayName', result.DisplayName);
      localStorage.setItem('userDetail', serializedState);
      localStorage.setItem('mYLog', serializedState);
      localStorage.setItem('shopId', result.shopId);
      localStorage.setItem('userTypeId', result.userDetails.UserTypeId);

     

      if (result.shopId === "MD") {
        navigate('/dashboard/app', { replace: true });
        }
        else {
        navigate('/dashboard', { replace: true });

      }
    }
    else {
      alert('Invalid User');
    }
  };

  const onChange = (e) => {
    e.persist();
    // debugger;
    setemployee({ ...employee, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField id="Email" onChange={onChange} name="Email" label="Email address" value={employee.Email} />

        <TextField onChange={onChange}
          value={employee.Password} name="Password" id="DepPasswordartment"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
