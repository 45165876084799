
import InsertCommitmentsPopup from "../Popups/InsertCommitmentsPopup";


function InsertCommitments(props) {

    return (

        <InsertCommitmentsPopup shopID={props.shopID} loginuserDetailId={props.loginuserDetailId} updateDashboard={props.updateDashboard}
            opencommit={props.opencommit} closecommit={props.closecommit} cancelcommit= { props.cancelcommit }
        />
        // open={props.open} close={props.close} cancel={props.cancel}
    );
}

export default InsertCommitments;
