import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';

// ** Date Formating
import dayjs from "dayjs";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    Tooltip,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    TextField,
    Select,
} from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { GetAdvanceDetails, GetAdvanceUsedDetails, UpdateAdvanceUsed } from '../components/api/DailyAccountApiService';
import Loader from '../components/loader/Loader';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHeadMy, UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// mock
// import USERLIST from '../_mock/user';
import { variables } from '../Variables';
import "./AdvanceView.css";
// ----------------------------------------------------------------------

const ADV_TABLE_HEAD = [
    { id: 'advid', label: 'No', alignRight: false },
    { id: 'date', label: 'Adv: Date', alignRight: false },
    { id: 'advby', label: 'Adv: By', alignRight: false },
    { id: 'advamt', label: 'Adv: Amt', alignRight: false },
    { id: 'advuddt', label: 'Adv: Used On', alignRight: false },
    { id: 'advudby', label: 'Adv Used By', alignRight: false },
    { id: 'advudamt', label: 'Adv Used Amt', alignRight: false },
    { id: 'advused', label: 'Adv Used?', alignRight: false },
    { id: '' },
];
const ADV_PAID_TABLE_HEAD = [
    { id: 'advusid', label: 'No', alignRight: false },
    { id: 'advusdate', label: 'Adv: Date', alignRight: false },
    { id: 'advby', label: 'Adv: By', alignRight: false },
    { id: 'advusamt', label: 'Adv: Amt', alignRight: false },
    { id: 'advususer', label: 'User', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function advdescendingComparator(a, b, advorderBy) {
    if (b[advorderBy] < a[advorderBy]) {
        return -1;
    }
    if (b[advorderBy] > a[advorderBy]) {
        return 1;
    }
    return 0;
}
function advpaiddescendingComparator(a, b, advpaidorderBy) {
    if (b[advpaidorderBy] < a[advpaidorderBy]) {
        return -1;
    }
    if (b[advpaidorderBy] > a[advpaidorderBy]) {
        return 1;
    }
    return 0;
}
function advgetComparator(order, advorderBy) {
    return order === 'desc'
        ? (a, b) => advdescendingComparator(a, b, advorderBy)
        : (a, b) => -advdescendingComparator(a, b, advorderBy);
}
function advpaidgetComparator(order, advpaidorderBy) {
    return order === 'desc'
        ? (a, b) => advpaiddescendingComparator(a, b, advpaidorderBy)
        : (a, b) => -advpaiddescendingComparator(a, b, advpaidorderBy);
}
function applySortFilter(array, comparator, query) {
    console.log('applySortFilter');
    console.log(array);

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.by.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}
function applySortFilter2(array, comparator, query) {
    console.log('applySortFilter2');
    console.log(array);

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.usby.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

const dropdownOptions = [
    { value: 'KO', label: 'KO' },
    { value: 'MD', label: 'MD' },
];

export default function UserPage() {
    const current = new Date();
    current.setDate(current.getDate() - 0)

    // const [open, setOpen] = useState(null);
    const [advno, setadvno] = useState('');

    const [advpage, setadvpage] = useState(0);
    const [advpaidpage, setadvpaidpage] = useState(0);

    const [advorder, setadvorder] = useState('asc');
    const [advpaidorder, setadvpaidorder] = useState('asc');

    const [advselected, setadvselected] = useState([]);
    const [advpaidselected, setadvpaidselected] = useState([]);

    const [advorderBy, setadvorderBy] = useState('advid');
    const [advpaidorderBy, setadvpaidorderBy] = useState('advid');

    const [advfilterName, setadvfilterName] = useState('');
    const [advpaidfilterName, setadvpaidfilterName] = useState('');

    const [advrowsPerPage, setadvrowsPerPage] = useState(10);
    const [advpaidrowsPerPage, setadvpaidrowsPerPage] = useState(10);
    const [loginuserDetailId, setloginuserDetailId] = useState(localStorage.getItem('userId'));
    const [loader, setLoader] = useState(false);


    const advhandleRequestSort = (event, property) => {
        const isAsc = advorderBy === property && advorder === 'asc';
        setadvorder(isAsc ? 'desc' : 'asc');
        setadvorderBy(property);
    };
    const advpaidhandleRequestSort = (event, property) => {
        const isAsc = advpaidorderBy === property && advpaidorder === 'asc';
        setadvpaidorder(isAsc ? 'desc' : 'asc');
        setadvpaidorderBy(property);
    };
    const advhandleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = advancelist.map((n) => n.advid);
            advselected(newSelecteds);
            return;
        }
        advselected([]);
    };
    const advpaidhandleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = advancepaidlist.map((n) => n.advusid);
            advpaidselected(newSelecteds);
            return;
        }
        advpaidselected([]);
    };


    const advhandleChangePage = (event, newPage) => {
        setadvpage(newPage);
    };
    const advpaidhandleChangePage = (event, newPage) => {
        setadvpaidpage(newPage);
    };

    const advhandleChangeRowsPerPage = (event) => {
        setadvpage(0);
        setadvrowsPerPage(parseInt(event.target.value, 10));
    };
    const advpaidhandleChangeRowsPerPage = (event) => {
        setadvpaidpage(0);
        setadvpaidrowsPerPage(parseInt(event.target.value, 10));
    };

    const advhandleFilterByNamepage = (event) => {
        setadvpage(0);
        setadvfilterName(event.target.value);
    };
    const advpaidhandleFilterByNamepage = (event) => {
        setadvpaidpage(0);
        setadvpaidfilterName(event.target.value);
    };
    const [advancelist, setadvancelist] = useState([{}]);
    const [advancepaidlist, setadvancepaidlist] = useState([{}]);

    const navigate = useNavigate();


    async function AdvanceisUSed(advusid, advno) {
        if (advno === '' || advno === 0) {
            alert('Please enter Advance list number');
        }
        else {
            try {
                setLoader(true);
                const res = await UpdateAdvanceUsed(advno, advusid);
                if (res.data.Id) {
                    alert(res.data.StatusMessage);
                    updateDashboard();
                }
                setLoader(false);
            } catch (error) {
                console.error('Error in AdvanceisUSed:', error);
            }

            // fetch(`${variables.API_URL}DailyAccounts/UpdateAdvanceUsed`, {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //         UserDetailId: advno,
            //         PunchType: advusid,
            //     })
            // })
            //     .then(res => { return res.json(); })
            //     .then((result) => {
            //         //* console.log(result);
            //         // if (result.interactID === 300) { alert(result.message); }
            //         // else {
            //         alert(result.message);
            //         updateDashboard();
            //         // }
            //     }, (error) => { alert(error); })
        }
    };

    const fetchAdvanceData = async (shop,logid) => {
        try {
            let shopId;
            if (loginuserDetailId <= 3) {
                if (shop) shopId = shop;
                else shopId = "KO";
            }
            else {
                shopId = localStorage.getItem('shopId');
                if (Number(shopId) === 1) {
                    shopId = 'KO';
                }
            }
            setLoader(true);
            const res = await GetAdvanceDetails(logid, shopId);
            fetchAdvancepaidData(shopId, logid)
            if (res.data) {
                setadvancelist(res.data)
            }
            setLoader(false);
        } catch (error) {
            // Handle other errors
            console.error('Error in fetchAdvanceData:', error);
        }

        // fetch(`${variables.API_URL}DailyAccounts/GetAdvanceDetails`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         UserDetailId: 0,
        //         PunchType: logid,
        //     })
        // })

        //     .then((response) => response.json())
        //     .then(
        //         (data) => setadvancelist(data));

        // console.log('GetUserDetails');
        // console.log(Userrr);
    };

    const fetchAdvancepaidData = async(shopId,logid) => {
        try {
            const res = await GetAdvanceUsedDetails(logid, shopId);
            if (res.data) {
                setadvancepaidlist(res.data)
            }
            setLoader(false);
        } catch (error) {
            // Handle other errors
            console.error('Error in fetchAdvancepaidData:', error);
        }

        // fetch(`${variables.API_URL}DailyAccounts/GetAdvanceUsedDetails`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         UserDetailId: 0,
        //         PunchType: 0,
        //     })
        // })

        //     .then((response) => response.json())
        //     .then(
        //         (data) => setadvancepaidlist(data));
        // console.log('GetUserDetails');
        // console.log(Userrr);

    };



    function updateDashboard() {
        fetchAdvanceData(selectedValue, 1);
        fetchAdvancepaidData(selectedValue, 1);
    }

    useEffect(() => {
        const b = JSON.parse(localStorage.getItem('mYLog'));
        if (b !== null) {
            updateDashboard();
        }
        else {
            navigate('/login', { replace: true });
        }
    }, []);

    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    // const isNotFound = !filteredUsers.length && !!filterName;


    const emptyRowsadv = advpage > 0 ? Math.max(0, (1 + advpage) * advrowsPerPage - advancelist.length) : 0;

    const filteredUsersadv = applySortFilter(advancelist, advgetComparator(advorder, advorderBy), advfilterName);

    const isNotFoundadv = !filteredUsersadv.length && !!advfilterName;


    const emptyRowsadvpaid = advpaidpage > 0 ? Math.max(0, (1 + advpaidpage) * advpaidrowsPerPage - advancepaidlist.length) : 0;

    const filteredUsersadvpaid = applySortFilter2(advancepaidlist, advpaidgetComparator(advpaidorder, advpaidorderBy), advpaidfilterName);

    const isNotFoundadvpaid = !filteredUsersadvpaid.length && !!advpaidfilterName;

    const [selectedValue, setSelectedValue] = useState('KO');

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
        fetchAdvanceData(event.target.value, 1);
    };

    return (
        <>
            <Helmet>
                <title> Advances | {variables.COMPANY_NAME} </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center">
                    <Typography variant="h4" gutterBottom mr={5}>
                        Advance's List
                    </Typography>
                    {loginuserDetailId <= 3 ? (
                        <Stack direction="row" alignItems="center" mb={2}>
                            <Typography variant="p" gutterBottom mr={2}>
                                Filter By :
                            </Typography>

                            <Select width={10} value={selectedValue} onChange={handleDropdownChange}>
                                {dropdownOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    ) : ('')
                    }
                </Stack>

                <Card>
                    <UserListToolbar numSelected={advselected.length} advfilterName={advfilterName} onFilterName={advhandleFilterByNamepage} />
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="female"
                        >
                            <FormControlLabel value="female" control={<Radio />} onClick={() => { fetchAdvanceData(selectedValue, 1) }} label="Show pending only" />
                            <FormControlLabel value="male" control={<Radio />} onClick={() => { fetchAdvanceData(selectedValue, 0) }} label="Show all" color='success' />


                        </RadioGroup>
                    </FormControl>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHeadMy
                                    advorder={advorder}
                                    advorderBy={advorderBy}
                                    headLabel={ADV_TABLE_HEAD}
                                    rowCount={advancelist.length}
                                    numSelected={advselected.length}
                                    onRequestSort={advhandleRequestSort}
                                    onSelectAllClick={advhandleSelectAllClick}
                                />
                                {loader ? (<div className="table-loader">
                                    <Loader />
                                </div>
                                ) : (
                                    <TableBody>
                                        {filteredUsersadv.slice(advpage * advrowsPerPage, advpage * advrowsPerPage + advrowsPerPage).map((row) => {

                                            const { id, date, by, amt, useddt, used, usedby, usedamt, username, avatarUrl } = row;

                                            const selectedUser = advselected.indexOf(id) !== -1;
                                            const shorten = username ? username.substring(0, 3) : '';
                                            // console.log(filteredUsers1)
                                            console.log('advuddt')
                                            return (
                                                <TableRow hover key={id} tabIndex={-1} >
                                                    <TableCell align="left">{id}</TableCell>
                                                    <TableCell align="left">{dayjs(date).format('DD-MMM')}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={by} src={'avatarUrl'} />
                                                            <Typography variant="subtitle2" noWrap>
                                                                {by}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>


                                                    <TableCell align="left">{amt}</TableCell>
                                                    <TableCell align="left">{dayjs(useddt === null && '' || useddt).format('DD-MMM')}</TableCell>
                                                    <TableCell align="left">{usedby}</TableCell>
                                                    <TableCell align="left">{usedamt}</TableCell>
                                                    <TableCell align="left">{used === 1 && 'Yes' || ''}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {<Tooltip title={shorten} followCursor>
                                                                {
                                                                    <Avatar alt={username} src={avatarUrl} />
                                                                }
                                                            </Tooltip>}
                                                        </Stack>
                                                    </TableCell>



                                                </TableRow>
                                            );
                                        })}
                                        {emptyRowsadv > 0 && (
                                            <TableRow style={{ height: 53 * emptyRowsadv }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                                {isNotFoundadv && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{advfilterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 30]}
                        component="div"
                        count={advancelist.length}
                        rowsPerPage={advrowsPerPage}
                        page={advpage}
                        onPageChange={advhandleChangePage}
                        onRowsPerPageChange={advhandleChangeRowsPerPage}
                    />
                </Card>

            </Container>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Advance's Paid List
                    </Typography>

                </Stack>

                <Card>
                    <UserListToolbar numSelected={advpaidselected.length} filterName={advpaidfilterName} onFilterName={advpaidhandleFilterByNamepage} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHeadMy
                                    advpaidorder={advpaidorder}
                                    advpaidorderBy={advpaidorderBy}
                                    headLabel={ADV_PAID_TABLE_HEAD}
                                    rowCount={advancepaidlist.length}
                                    numSelected={advpaidselected.length}
                                    onRequestSort={advpaidhandleRequestSort}
                                    onSelectAllClick={advpaidhandleSelectAllClick}
                                />
                                {loader ? (<div className="table-loader">
                                    <Loader />
                                </div>
                                ) : (
                                    <TableBody>
                                        {filteredUsersadvpaid.slice(advpaidpage * advpaidrowsPerPage, advpaidpage * advpaidrowsPerPage + advpaidrowsPerPage).map((row) => {

                                            const { usid, tranid, usdate, usamt, usby, username, avatarUrl } = row;

                                            const selectedUser = advpaidselected.indexOf(usid) !== -1;
                                            const shorten = username ? username.substring(0, 3) : '';
                                            // console.log(filteredUsers1)


                                            // console.log(date1)
                                            // console.log(date2)
                                            return (
                                                <TableRow hover key={usid} tabIndex={-1} >
                                                    <TableCell align="left">{usid}</TableCell>
                                                    <TableCell align="left">{dayjs(usdate).format('DD-MMM')}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={usby} src={'avatarUrl'} />
                                                            <Typography variant="subtitle2" noWrap>
                                                                {usby}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>


                                                    <TableCell align="left">{usamt}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {<Tooltip title={shorten} followCursor>
                                                                {
                                                                    <Avatar alt={username} src={avatarUrl} />
                                                                }
                                                            </Tooltip>}
                                                        </Stack>
                                                    </TableCell>


                                                    <TableCell align="left">


                                                        <TextField variant="outlined" className='hf' type="number" size="small" name="advno"

                                                            onChange={(e) => { setadvno(e.target.value) }}
                                                        />
                                                        <Button variant="contained" size='small'
                                                            onClick={() => { AdvanceisUSed(usid, advno) }}

                                                            startIcon={<Iconify icon="eva:plus-fill" />}>
                                                            Update
                                                        </Button>
                                                    </TableCell>




                                                </TableRow>
                                            );
                                        })}
                                        {emptyRowsadvpaid > 0 && (
                                            <TableRow style={{ height: 53 * emptyRowsadvpaid }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                                {isNotFoundadvpaid && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{advpaidfilterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 30]}
                        component="div"
                        count={advancepaidlist.length}
                        rowsPerPage={advpaidrowsPerPage}
                        page={advpaidpage}
                        onPageChange={advpaidhandleChangePage}
                        onRowsPerPageChange={advpaidhandleChangeRowsPerPage}
                    />
                </Card>
            </Container>

        </>
    );
}
