import { LinearProgress, Button, makeStyles, createStyles } from "@mui/material";
import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import { variables } from '../Variables';

function ProgressBarPopup() {
   
    const [level, setLevel] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function imageUpload(e) {
        e.preventDefault();
        console.log('file');
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);
        console.log(e.target.files[0])
        fetch(`${variables.API_URL}MagicFileRead/savefile`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                // setState({ modalPhotoFileName: data })
                alert(data);
                handleClose();
                
            }, (error) => {
                alert(error);
                handleClose();
            })
        
    }
    useEffect(() => {
        const timer = setInterval(() => {
            setLevel((newLevel) => (newLevel >= 100 ? 0 : newLevel + 10));
        }, 700);
        return () => {
            clearInterval(timer);
        };
    }, []);

    
   	
    return (
        <>
            <Button variant="contained" color="success">              
                {/* <input className='m1' value='' accept=".dbf" type='file' onClick={handleShow} onHide={handleClose}
                    onChange={
                        event => {
                            if (event?.target?.files.length > 0)
                                props.onChange(event.target.files);
                        }
                    }
                /> */}
                <input className='m1' value='' accept=".dbf" type='file' onClick={handleShow}
                    onChange={imageUpload}
                />
               
            </Button>
            
            <Modal style={{ zIndex: 1100 }}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                
                   
                
                <Modal.Body>
                    <LinearProgress color="success"  value={level} />
                    
                </Modal.Body>
                <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                    Close
                </button>
            </Modal>
        </>
       
    );
}

export default ProgressBarPopup;