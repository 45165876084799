
import { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

// ** Date Formating
import dayjs from "dayjs";
// @mui
import {
    Card,
    Table,
    Paper,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TextField,
    TableHead,
    TableFooter,
} from '@mui/material';
import { filter } from 'lodash';

import "./InsertAdvancessPopup.css";

// components
import Iconify from '../components/iconify';
import { GetDailyCustomerCommitments, InsertCommitments } from '../components/api/DailyAccountApiService';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { UserListHeadNNP } from '../sections/@dashboard/user';

const COMMITMENT_TABLE = [
    { id: 'customerName', label: 'Customer Name', alignRight: false },
    { id: 'open', label: 'Open', alignRight: false },
    { id: 'closed', label: 'Closed', alignRight: false },
    { id: 'cancel', label: 'Cancel', alignRight: false },
];

function InsertCommitmentPopup(props) {

    const navigate = useNavigate();
    const current = new Date();
    const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const [commitOpen, setcommitOpen] = useState(0);
    const [commitClose, setcommitClose] = useState(0);
    const [commitCancel, setcommitCancel] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [loginuserDetailId, setLoginuserDetailId] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(65);

    const [SaveStatus, setSaveStatus] = useState(0);
    const [show, setShow] = useState(false);
    const [userCommitment, setUserCommitment] = useState([{}]);
    const [filterName, setFilterName] = useState('');
    const filteredCommitments = applySortFilter(userCommitment, getComparator(order, orderBy), filterName);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const Resetcontrol = (e) => {
        e.current.style.border = "none";
    }

    const insertToDB = async (e, commit, commitId) => {
        e.preventDefault(); // Prevent form from reloading the page
        if (commit === 1) {
            if (!customerName) {
                alert('Please Enter Customer Name');
                return; // Exit the function if customer name is null
            }
            const payload = {
                TRAN_ShopID: props.shopID,
                TRAN_ID: props.trid,
                TRAN_CommitOpen: 1,
                CustomerName: customerName,
            };
            try {
                const res = await InsertCommitments(payload);
                const result = res.data;
                if (result.Id > 0) {
                    handleCloseModal(false);
                    alert(result.StatusMessage);
                    ClearAccountsData();
                    props.updateDashboard();
                }
            } catch (error) {
                console.error('Error inserting commitments:', error);
            }
            return;
        }
        let payload = {};
        if (commit === 2) {
            payload = {
                TRAN_CommitID: commitId,
                TRAN_CommitClosed: 1,
            };
        } else if (commit === 3) {
            payload = {
                TRAN_CommitID: commitId,
                TRAN_CommitCanceled: 1,
            };
        } else if (commit === 4) {
            payload = {
                TRAN_CommitID: commitId,
                TRAN_CommitOpen: 1,
            };
        }
        try {
            const res = await InsertCommitments(payload);
            const result = res.data;
            if (result.Id > 0) {
                handleCloseModal(false);
                alert(result.StatusMessage);
                ClearAccountsData();
                props.updateDashboard();
            }
        } catch (error) {
            console.error('Error inserting commitments:', error);
        }
    };

    const insertCommitments = async (payload) => {
        const res = await InsertCommitments(payload);
        const result = res.data;
        if (result.Id > 0) {
            handleCloseModal(false);
            alert(result.StatusMessage);
            ClearAccountsData();
            props.updateDashboard();
        }
    }

    const ClearAccountsData = () => {
        setcommitOpen(0);
        setcommitClose(0);
        setcommitCancel(0);
        setCustomerName('');
    };

    const handleCloseModal = (st) => {
        setShow(st)
    }

    const fetchCommitmentData = async () => {
        try {
            const res = await GetDailyCustomerCommitments(props.shopID, props.trid);
            if (res.data) {
                setUserCommitment(res.data);
            }
        }
        catch (error) {
            console.error('Error in fetchCommitmentData:', error);
        }
    };

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    useEffect(() => {
        if (props.trid) {
            fetchCommitmentData();
        }
    }, [props]);

    return (
        <>

            <Button size="small" className='sml' onClick={handleShow} startIcon={<Iconify icon="eva:plus-fill" />}>{props.data}</Button>

            <Modal style={{ zIndex: 1100 }}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Customer Commits on {dayjs(props.date).format('DD-MMM-YYYY')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id='InsertCommitmentPopup' name='Edwit' >
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead />
                                <TableBody>
                                    <TableRow>
                                        {props.isOpen === 1 ? (
                                            <>
                                                <TableCell component="th" scope="row">
                                                    <TextField variant="standard" type="text" size="small" label="Customer Name "
                                                        value={customerName}
                                                        onChange={(e) => { setCustomerName(e.target.value) }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <button onClick={(e) => insertToDB(e,1)} form='InsertCommitmentPopup' className="bt-green css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                                                        Add
                                                    </button>
                                                </TableCell>
                                            </>
                                        ) : ('')}
                                    </TableRow>
                                </TableBody>
                                <TableFooter />
                            </Table>
                        </TableContainer>

                        <Card>
                            <Scrollbar>
                                <Paper style={{ overflow: 'auto' }}>
                                    <TableContainer >

                                        <Table stickyHeader aria-label="sticky table">
                                            <UserListHeadNNP
                                                order={order}
                                                orderBy={orderBy}
                                                // headLabel={TABLE_HEAD}
                                                headLabel={COMMITMENT_TABLE}
                                                rowCount={userCommitment.length}
                                                numSelected={selected.length}
                                            />
                                            <TableBody>

                                                {filteredCommitments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                    const { id, commitId, date, open, closed, cancelled, customerName } = row;
                                                    return (
                                                        <TableRow hover key={id} tabIndex={-1}>
                                                            <TableCell align="right" >{customerName}</TableCell>
                                                            {props.isOpen === 0 ? (
                                                                <TableCell align="right">
                                                                    <Button size="small" className='sml' onClick={(e) => insertToDB(e, 4, commitId)} startIcon={<Iconify icon="eva:plus-fill" />}>{open}</Button>
                                                                </TableCell>
                                                            ) : (
                                                                    <TableCell align="right" >{open}</TableCell>
                                                            )}
                                                            {/* <TableCell align="right" >{open}</TableCell> */}
                                                            {props.isOpen === 0 ? (
                                                                <TableCell align="right">
                                                                    <Button size="small" className='sml' onClick={(e) => insertToDB(e,2, commitId)} startIcon={<Iconify icon="eva:plus-fill" />}>{closed}</Button>
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell align="right" >{closed}</TableCell>
                                                            )}
                                                            {props.isOpen === 0 ? (
                                                                <TableCell align="right">
                                                                    <Button size="small" className='sml' onClick={(e) => insertToDB(e,3, commitId)} startIcon={<Iconify icon="eva:plus-fill" />}>{cancelled}</Button>
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell align="right" >{cancelled}</TableCell>
                                                            )}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Scrollbar>
                        </Card>

                    </form>
                </Modal.Body>
                <Modal.Footer>

                    <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default InsertCommitmentPopup;