
import InsertCrOutPopup from "../Popups/InsertCrOutPopup";


function InsertCrOut(props) {
  return (
     
    <InsertCrOutPopup hide={props.hide} dailyCrOutTrans={props.dailyCrOutTrans} date={props.date} trid={props.trid} id={props.id}
      crout={props.crout} creditouttxt={props.creditouttxt}
      updateDashboard={props.updateDashboard} />
      
  );
}

export default InsertCrOut;
