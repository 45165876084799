
import InsertAdvancessPopup from "../Popups/InsertAdvancessPopup";


function InsertAdvancess(props) {
  return (
     
    <InsertAdvancessPopup hide={props.hide} dailyAdvTrans={props.dailyAdvTrans} date={props.date} trid={props.trid} id={props.id} advance={props.advance} advancetxt={props.advancetxt} updateDashboard={props.updateDashboard} />
      
  );
}

export default InsertAdvancess;
