export const saveDailyAccountData = (response) => {
    let payload = {}
    if (response !== "") {
      payload = {
        "TRAN_ID" : Number(response.TRAN_ID) || 0,
        // "TRAN_DATE": response.TRAN_DATE,
        "TRAN_OPCash": Number(response.TRAN_OPCash),
        "TRAN_TotaBillSales": Number(response.TRAN_TotaBillSales),
        "TRAN_TotaNonBillSales": Number(response.TRAN_TotaNonBillSales),
        "TRAN_LastBillNo": response.TRAN_LastBillNo,
        "TRAN_CashIn": Number(response.TRAN_CashIn),
        "TRAN_OldBills": Number(response.TRAN_OldBills),
        "TRAN_GPay": Number(response.TRAN_GPay),
        "TRAN_CardPay":  Number(response.TRAN_CardPay),
        "TRAN_Expence": Number(response.TRAN_Expence),
        "TRAN_Take": Number(response.TRAN_Take),
        "TRAN_CashClose": Number(response.TRAN_CashClose),
        "TRAN_CashText": response.TRAN_CashText,
        "UserDetailId": Number(response.UserDetailId),
        "TRAN_TotalIn": Number(response.TRAN_TotalIn),
        "TRAN_TotalOut": Number(response.TRAN_TotalOut),
        "TRAN_Adjest": Number(response.TRAN_Adjest),
        "TRAN_ShopID": response.TRAN_ShopID,
        "InsertAnyWay": response.InsertAnyWay,
      }
    }
    return payload;
  };

export const saveDailyCommitmentsData = (response) => {
    let payload = {}
    if (response !== "") {
      payload = {
        "TRAN_CommitID" : response.TRAN_ID || 0,
        "TRAN_CommitDate": response.TRAN_CommitDate,
        "TRAN_CommitOpen": response.TRAN_CommitOpen,
        "TRAN_CommitClosed": response.TRAN_CommitClosed,
        "TRAN_CommitCanceled": response.TRAN_CommitCanceled,
        "TRAN_ShopID": response.TRAN_ShopID,
      }
    }
    return payload;
  };

export const DailyAccountUpdateData = (response) => {
    let payload = {}
    if (response !== "") {
      payload = {
        "InsertDate" : response.InsertDate,
        "TRAN_ID" : Number(response.TRAN_ID),
        "Amount" : Number(response.Amount),
        "GivenBy" : response.GivenBy,
      }
    }
    return payload;
  };