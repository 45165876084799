
import React, { useState, useRef } from 'react';
import Alert from '@mui/material/Alert';
import Modal from 'react-bootstrap/Modal';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';


// @mui
import {
  Table, Paper, Button, TableRow, TableBody, TableCell,
  TableContainer, TextField, TableHead, TableFooter,
} from '@mui/material';



import { id } from 'date-fns/locale';
import "./InsertAccountsPopup.css";

import Swal from 'sweetalert2';
// components
import Iconify from '../components/iconify';
import { variables } from '../Variables';
import { InsertDailyAccount } from '../components/api/DailyAccountApiService';
import { saveDailyAccountData } from '../model/DailyAccountModel';

function InsertAccountsPopup(props) {
  const current = new Date();
  const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const [date, setdate] = useState(current);

  const [OPCash, setOPCash] = useState(props.oPCash);
  const [ShiftoPCash, setShiftoPCash] = useState(props.shiftoPCash);
  const [TranDATE, setTranDATE] = useState();
  const [TotaBillSales, setTotaBillSales] = useState(0);
  const [TotaNonBillSales, setTotaNonBillSales] = useState(0);
  const [LastBillNo, setLastBillNo] = useState(0);
  const [CreditIN, setCreditIN] = useState(0);
  const [Advance, setAdvance] = useState(0);
  const [CashIn, setCashIn] = useState(0);
  const [PurchaseCashPayment, setPurchaseCashPayment] = useState(0);
  const [GPay, setGPay] = useState(0);
  const [CardPay, setCardPay] = useState(0);
  const [Expence, setExpence] = useState(0);
  const [CreditOut, setCreditOut] = useState(0);
  const [Take, setTake] = useState(0);
  const [CashClose, setCashClose] = useState(0);
  const [CashText, setCashText] = useState('');
  const [OldBills, setOldBills] = useState(0);
  const [AdvUsed, setAdvUsed] = useState(0);
  const [CreditINTxt, setCreditINTxt] = useState('');
  const [AdvanceTxt, setAdvanceTxt] = useState('');
  const [PurchaseTxt, setPurchaseTxt] = useState('');
  const [AdvanceUsedTxt, setAdvanceUsedTxt] = useState('');
  const [CreditOutTxt, setCreditOutTxt] = useState('');
  const [TotalIn, setTotalIn] = useState(0);
  const [TotalOut, setTotalOut] = useState(0);
  const [Adjest, setAdjest] = useState(0);

  const [SaveStatus, setSaveStatus] = useState(0);
  const [show, setShow] = useState(false);

  const refAdvanceUsedTxt = useRef();
  const refLastBillNo = useRef();


  const refCreditOutTxt = useRef();
  const refCreditINTxt = useRef();
  const refAdvanceTxt = useRef();
  // const refAdvanceUsedTxt = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Resetcontrol = (e) => {
    e.current.style.border = "none";
  }

  const insertToDB = async (SaveStatus) => {
    const response = {
      TRAN_ID:0,
      // TRAN_DATE: TranDATE,
      TRAN_OPCash: props.oPCash,
      TRAN_TotaBillSales: TotaBillSales,
      TRAN_TotaNonBillSales: TotaNonBillSales,
      TRAN_LastBillNo: LastBillNo,
      TRAN_CashIn: CashIn,
      TRAN_OldBills: OldBills,
      TRAN_GPay: GPay,
      TRAN_CardPay: CardPay,
      TRAN_Expence: Expence,
      TRAN_Take: Take,
      TRAN_CashClose: CashClose,
      TRAN_CashText: CashText,
      UserDetailId: props.id,
      TRAN_TotalIn: TotalIn,
      TRAN_TotalOut: TotalOut,
      TRAN_Adjest: Adjest,
      TRAN_ShopID: props.shopID,
      InsertAnyWay:SaveStatus || 0,
    };
    const data = saveDailyAccountData(response);
    const result = await InsertDailyAccount(data);
    const res = result.data;
    if (res.Id > 0) {
      handleCloseModal(false);
      alert(res.StatusMessage);
      ClearAccountsData();
      props.updateDashboard();
    }
    else {
      Swal.fire({
        title: 'Are you sure?',
        text: res.StatusMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Save it!',
        customClass: {
          container: 'swal2-container'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          insertAccountsData(1);
        }
      });
    }
  }

  const insertAccountsData = (SaveStatus) => {
    setShow(true);
    if (LastBillNo === 0 || LastBillNo.trim() === "") {
      refLastBillNo.current.focus();
      refLastBillNo.current.style.border = "2px solid #fd9797";
      alert("\"Please enter \"Last bill number\"! ");
      return;
    }
    if (TotaBillSales <= 0 || CashClose <= 0) {
      alert("\"Total sales\" and \"Closing cash\" cannot be zero! ");
      return;
    }
    if (SaveStatus === 1) {
      insertToDB(SaveStatus);
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to save?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Save it!'
      }).then((result) => {
        if (result.isConfirmed) {
          insertToDB(0);
        }
      });
    }
  };

  const ClearAccountsData = () => {
    setTotaBillSales(0);
    setOPCash(0);
    setTotaBillSales(0);
    setTotaNonBillSales(0);
    // setCreditIN(0);
    // setAdvance(0);
    setCashIn(0);
    // setPurchaseCashPayment(0);
    setGPay(0);
    setCardPay(0);
    setExpence(0);
    // setCreditOut(0);
    setTake(0);
    setCashClose(0);
    setCashText(0);
    setOldBills(0);
    setTranDATE(dayjs(current));
    // setCreditINTxt('');
    // setAdvanceTxt('');
    // setPurchaseTxt('');
    // setAdvanceUsedTxt('');
    // setCreditOutTxt('');
  };

  const handleCloseModal = (st) => {
    setShow(st)
  }

  return (
    <>

      <Button variant="contained" onClick={handleShow} startIcon={<Iconify icon="eva:plus-fill" />}>
        Insert Account
      </Button>
      {/*
      <button onClick={handleShow}  className="css-12vebo6-MuiButtonBase-root-MuiButton-root block m-2 bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
        Update
  </button> */}

      <Modal style={{ zIndex: 1100 }}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Today's {props.shopID === 'KO' && 'Kovaipudure' || 'Madukkarai'} Accounts -{datee}</Modal.Title>

          {/* <Modal.Title>
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={[
                  'DatePicker',
                  
                ]}
              >Account of -  {
                  props.id !== 1 &&
                  <DatePicker value={dayjs(TranDATE)} format="DD/MMM/YYYY" readOnly onChange={(e) => {
                    const d = new Date(e);
                     console.log('E');
                     console.log(d);
                    setTranDATE(d);
                     console.log({ TranDATE });
                  }} />
                  ||
                  <DatePicker value={dayjs(TranDATE)} format="DD/MMM/YYYY" onChange={(e) => {
                    const d = new Date(e);
                     console.log('E');
                     console.log(d);
                    setTranDATE(d);
                     console.log({ TranDATE });
                  }} />
                } 
              </DemoContainer>
            </LocalizationProvider>
            
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <form id='InserAccountsPopup' name='Edwit' onSubmit={(e) => {
            e.preventDefault();
            // console.log('Form Submited');
            insertAccountsData(0);
            // console.log(props.id, name, role);

            // console.log(' after');

          }}>

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead />
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField variant="standard" className='hf red' type="number" size="small" name="opCash" label="Opening Cash ₹ "
                        value={props.oPCash}
                        onChange={(e) => { setOPCash(e.target.value) }} />

                      <TextField variant="standard" className='hf red' type="number" size="small" name="ShiftopCash" label="Shift Opening Cash ₹ "
                        value={props.shiftoPCash}
                        onChange={(e) => { setShiftoPCash(e.target.value) }} />

                    </TableCell>

                    <TableCell align="right">
                      {/**
                      <TextField variant="standard" type="number" size="small" name="purCashPay" label="Pur-Cash Payment ₹ "
                        value={PurchaseCashPayment}
                        onChange={(e) => { setPurchaseCashPayment(e.target.value) }}
                      /> */}
                      <TextField variant="standard" type="number" size="small" name="cardSwipe" label="Card swipe amount ₹ "
                        value={CardPay}
                        onChange={(e) => { setCardPay(e.target.value) }}
                      />
                    </TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField variant="standard" className='hf' type="number" size="small" name="Bill" label="Total Sales ₹ "
                        value={TotaBillSales}
                        onChange={(e) => { setTotaBillSales(e.target.value) }} />
                      <TextField variant="standard" className='hf' size="small" name="LastBill" label="Last Bill No "
                        value={LastBillNo}
                        ref={refLastBillNo}
                        onFocus={(e) => { Resetcontrol(refLastBillNo) }}
                        onChange={(e) => { setLastBillNo(e.target.value) }} />
                    </TableCell>
                    <TableCell align="right">
                      <TextField variant="standard" type="number" size="small" name="gpay" label="GPay Amount ₹ "
                        value={GPay}
                        onChange={(e) => { setGPay(e.target.value) }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row"><TextField variant="standard" type="number" size="small" name="nonBill" label="Non Bills ₹ "
                      value={TotaNonBillSales}
                      onChange={(e) => { setTotaNonBillSales(e.target.value) }}
                    /></TableCell>
                    <TableCell align="right">
                      <TextField variant="standard" type="number" size="small" name="oldBill" label="Old Bills ₹ "
                        value={OldBills}
                        onChange={(e) => { setOldBills(e.target.value) }}
                      />
                    </TableCell>


                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row"><TextField variant="standard" type="number" size="small" name="cashIn" label="Cash In ₹ "
                      value={CashIn}
                      onChange={(e) => { setCashIn(e.target.value) }}
                    /></TableCell>
                    <TableCell align="right">
                      <TextField variant="standard" type="number" size="small" name="exp" label="Shop Expencess ₹ "
                        value={Expence}
                        onChange={(e) => { setExpence(e.target.value) }}
                      />
                    </TableCell>

                  </TableRow>

                  <TableRow>
                    <TableCell ><TextField variant="standard" type="number" size="small" small name="cashTake" label="Cash Taken ₹ "
                      value={Take}
                      onChange={(e) => { setTake(e.target.value) }}
                    /></TableCell>
                    <TableCell align="right">
                      <TextField variant="standard" type="number" size="small" name="clsCash" label="Closing Cash ₹ "
                        value={CashClose}
                        onChange={(e) => { setCashClose(e.target.value) }}
                      />
                    </TableCell>
                  </TableRow>



                </TableBody>
                <TableFooter />
              </Table>
            </TableContainer>
          </form>
        </Modal.Body>
        <Modal.Footer>

          <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
            Close
          </button>

          <button onClick={handleClose} form='InserAccountsPopup' className="bt-green css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
            Add Account
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default InsertAccountsPopup;