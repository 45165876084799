export const variables = {
    // API_URL: "http://localhost:5244/api/",
    // API_URL: "https://danny.liveagilelife.com/api/",
    // API_URL: "https://api.nalam.co.in/api/",
   API_URL: "https://localhost:7222/api/",
   // API_URL: "http://163.47.153.98:86/api/",

  
   //  PHOTO_URL: "http://localhost:5244/Photos/",
   // PHOTO_URL: "https://api.nalam.co.in/Photos/",
   PHOTO_URL: "https://localhost:7222/Photos/",
   // PHOTO_URL: "http://163.47.153.98:86/Photos/",
    // ?Yz4r957e 
    COMPANY_NAME: "New Nalam Pharmacy"
}