
import EditAccountsPopup from "../Popups/EditAccountsPopup";

function EditAccounts(props) {
  return (
     
    <EditAccountsPopup
      // shiftoPCash={props.shiftoPCash}
      hide={props.hide}
      oPCash={props.oPCash}
      trid={props.trid}
      date={props.date}
      userDetailId={props.userDetailId}
      sales={props.sales}
      nonbill={props.nonbill}
      lastBillNo={props.lastBillNo}
       advance={props.advance} crin={props.crin}
      cashin={props.cashin}
      // TotalIn={props.TotalIn}      // Adjest={props.Adjest}
       pur={props.pur}      
      card={props.card} gpay={props.gpay} oldbill={props.oldbill}
       advused={props.advused}      
      exp={props.exp}
       crout={props.crout} 
      take={props.take}
      cashbox={props.cashbox}
      // TotalOut={props.TotalOut}      // name={props.name}
      // crintxt={props.crintxt} advancetxt={props.advancetxt} purchasetxt={props.purchasetxt}
      // advanceusedtxt={props.advanceusedtxt} creditouttxt={props.creditouttxt}
      updateDashboard={props.updateDashboard}
    />
    
      
  );
}

export default EditAccounts;
