
import InsertAccountsPopup from "../Popups/InsertAccountsPopup";


function InsertAccounts(props) {

  return (
     
    <InsertAccountsPopup shopID={props.shopID} css={props.css} shiftoPCash={props.shiftoPCash} oPCash={props.oPCash} id={props.id} updateDashboard={props.updateDashboard} />
      
  );
}

export default InsertAccounts;
