import axios from "axios";
import { SetupInterceptors } from "./SetupInterceptors";

const apiClient = axios.create({
  // baseURL: 'https://localhost:7222/api'
  baseURL: 'https://api.nalam.co.in/api'
});

// SetupInterceptors(apiClient);

export default apiClient;
