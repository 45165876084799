
import InsertCrInPopup from "../Popups/InsertCrInPopup";


function InsertCrIn(props) {
  return (
     
    <InsertCrInPopup hide={props.hide} dailyCrInTrans={props.dailyCrInTrans} date={props.date} trid={props.trid}
      id={props.id} crin={props.crin} crintxt={props.crintxt} updateDashboard={props.updateDashboard} />
      
  );
}
export default InsertCrIn;
