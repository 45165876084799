
import InsertPurchPopup from "../Popups/InsertPurchPopup";


function InsertPurch(props) {
  return (
     
    <InsertPurchPopup hide={props.hide} dailyPurchTrans={props.dailyPurchTrans} date={props.date} trid={props.trid} id={props.id}
      pur={props.pur} purchasetxt={props.purchasetxt}
      updateDashboard={props.updateDashboard} />
      
  );
}

export default InsertPurch;
