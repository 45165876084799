
import InsertAdvancessPopup from "../Popups/InsertAdvUsedPopup";


function InsertAdvUsed(props) {
  return (
     
    <InsertAdvancessPopup hide={props.hide} dailyAdvUsTrans={props.dailyAdvUsTrans}
      date={props.date} trid={props.trid} id={props.id}
      advused={props.advused} advanceusedtxt={props.advanceusedtxt} updateDashboard={props.updateDashboard} />
      
  );
}

export default InsertAdvUsed;
