
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

// @mui
import {
    Table,
    Paper,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TextField,
    TableHead,
    TableFooter,
} from '@mui/material';
import "./InsertAdvancessPopup.css";

// components
import Iconify from '../components/iconify';

function CustomizedDatePopup(props) {

    const navigate = useNavigate();
    const current = new Date();
    const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const [show, setShow] = useState(false);



    const handleClose = () => {
        setShow(false);
        clear();
    } 
    const handleShow = () => setShow(true);

    const handleCloseModal = (st) => {
        setShow(st);
        clear();
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (fromDate) {
            if (toDate) {
                props.submit(props.shopId,fromDate, toDate);
                handleClose();
            } else {
                alert("\"Please Enter Valid To Date");
            }
        }
        else {
            alert("Please Enter Valid From Date");
        }
    }

    const clear = () => {
        setFromDate('');
        setToDate('');
    }

    const formatDate = (date) => {
        const d = new Date(date);
        const month = `0${d.getMonth() + 1}`.slice(-2);
        const day = `0${d.getDate()}`.slice(-2);
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const maxDate = formatDate(new Date());

    return (
        <>

            <Button color='inherit' variant="contained" size='small' onClick={handleShow} startIcon={<Iconify icon="eva:plus-fill" />}>Customize Date</Button>

            <Modal style={{ zIndex: 1100 }}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Customize Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id='CustomizedDatePopup' name='Edwit' onSubmit={handleFormSubmit}>

                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead />
                                <TableBody>
                                    <TableRow>

                                        <TableCell component="th" scope="row">
                                            <TextField variant="standard" type="date" size="small" name="adv" label="From Date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} InputLabelProps={{ shrink: true }}
                                                inputProps={{ max: maxDate }} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField variant="standard" type='date' size="small" fullWidth name="advd" label="To Date" onChange={(e) =>setToDate(e.target.value)} value={toDate} maxRows={new Date()} InputLabelProps={{ shrink: true }}
                                                inputProps={{ max: maxDate, min:fromDate }} />
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                                <TableFooter />
                            </Table>
                        </TableContainer>



                    </form>
                </Modal.Body>
                <Modal.Footer>

                    <button onClick={handleClose} className="css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                        Close
                    </button>

                    <button form='CustomizedDatePopup' className="bt-green css-12vebo6-MuiButtonBase-root-MuiButton-root font-bold py-2 px-4">
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default CustomizedDatePopup;