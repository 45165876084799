// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


const navConfigMD = [
  {
    title: 'dashboard md',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Advances',
    path: '/dashboard/adv',
    icon: icon('adv'),
  },
  {
    title: 'Credits',
    path: '/dashboard/cr',
    icon: icon('cr'),
  },
  {
    title: 'NonBill',
    path: '/dashboard/nonbill',
    icon: icon('nonbill'),
  },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Advances',
  //   path: '/dashboard/adv',
  //   icon: icon('adv'),
  // },
  // {
  //   title: 'Credits',
  //   path: '/dashboard/cr',
  //   icon: icon('cr'),
  // },
  // {
  //   title: '100 Days',
  //   path: '/dashboard/ac100',
  //   icon: icon('days'),
  // },
  // {
  //   title: 'NonBill',
  //   path: '/dashboard/nonbill',
  //   icon: icon('nonbill'),
  // },
  // {
  //   title: 'Attendance',
  //   path: '/dashboard/empatten',
  //   icon: icon('attendance'),
  // },
  {
    title: 'login',
    path: '/login',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];


export default navConfigMD;
