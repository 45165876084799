// *** Edwin
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// *** Edwin Tab
import * as React from 'react';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';


import "./DashboardAppPage.css";

// ** Date Formating
import dayjs from "dayjs";

// components

// @mui
import {
    Grid,
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Tooltip,
    Checkbox,
    TableRow,
    Badge,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    CircularProgress,
    TableContainer,
    TablePagination,
    Chip,
    Button
} from '@mui/material';

import { filter } from 'lodash';

// Popus
import { v4 as uuidv4 } from 'uuid';
import InsertAdvancess from './InsertAdvancess'
import InsertAccounts from './InsertAccounts'
import InsertCrIn from './InsertCrIn'
import EditAccounts from './EditAccounts'
import InsertPurch from './InsertPurch'
import InsertAdvUsed from './InsertAdvUsed'
import InsertCrOut from './InsertCrOut'
import FileProgressBar from './FileProgressBar'


// components
import Iconify from '../components/iconify';
import Label from '../components/label';
// sections
import {
    AppTasks,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
} from '../sections/@dashboard/app';

import { GetUserDetails } from '../components/api/DailyAccountApiService';

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHeadNNP, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { variables } from '../Variables';

import "./NonBillView.css"; 



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',

    boxShadow: 24,
    p: 4,
};





// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'no', label: 'No', alignRight: false },
    { id: 'punchin', label: 'Punch In', alignRight: false },
    { id: 'punchout', label: 'Punch Out', alignRight: false },
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Employee Name', alignRight: false },
    { id: 'hours', label: 'Hours', alignRight: false },
];

const ACCOINT_TABLE = [
    { id: 'no', label: 'No', alignRight: false },
    { id: 'punchin', label: 'Punch In', alignRight: false },
    { id: 'punchout', label: 'Punch Out', alignRight: false },

    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Employee Name', alignRight: false },
    { id: 'hours', label: 'Hours', alignRight: false },
    
];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

function GetGraphData(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].sales);
}
function GetGraphDataPurchase(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].purchase);
}
function GetGraphDataPayment(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].payment);
}
function GetGraphDataTake(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].take);
}
function GetGraphDataPur(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].pur);
}
function GetGraphDataDate(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0].date);
}

function applySortFilter2(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


function addClick() {
    console.log('ssss');
}


export default function DashboardAppPage() {
    const current = new Date();
    const datee = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const theme = useTheme();


    const [open, setOpen] = useState(false);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('date');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(65);


    /** 
    http://apassant.net/2012/01/16/timeout-for-html5-localstorage/
  
  var hours = 24; // Reset when storage is more than 24hours
  var now = new Date().getTime();
  var setupTime = localStorage.getItem('setupTime');
  if (setupTime == null) {
      localStorage.setItem('setupTime', now)
  } else {
      if(now-setupTime > hours*60*60*1000) {
          localStorage.clear()
          localStorage.setItem('setupTime', now);
      }
  }
    
    */
    const [selmonths, setselmonths] = useState(1);
    const [lastActiveIn, setlastActiveIn] = useState();
    const [loginuserDetailId, setloginuserDetailId] = useState();
    const [userPunchStatus, setuserPunchStatus] = useState();
    const [userPunchTxt, setuserPunchTxt] = useState();
    const [userName, setuserName] = useState();
    const [userImage, setuserImage] = useState();

    




    // *** Edwin
    const [account, setUserAccount] = useState([{}]);
    const [accountStatus, setAccountStatus] = useState([{}]);

    const [Userrr, setUserLis] = useState([{}]);


    const fetchUserData = async (logid) => {
        try {
            const res = await GetUserDetails(logid, 0);
            if (res.data) {
                setUserLis(res.data);
            }
        } catch (error) {
            console.error('Error in fetchUserData:', error);
        }
        // fetch(`${variables.API_URL}DailyAccounts/GetUserDetails`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         UserDetailId: logid,
        //         PunchType: 0,
        //     })
        // })



            // fetch(`${variables.API_URL}DailyAccounts/GetUserDetails`)
            // .then((response) => response.json())
            // .then(
            //     (data) => setUserLis(data));
        // console.log('GetUserDetails');
        // console.log(Userrr);
    };

    const chkUserLogin = () => {
        const b = JSON.parse(localStorage.getItem('mYLog'));
        console.log('mYLog');
        console.log({ b });
        if (b === null) {
            console.log('AccountPopover.js - No user loged');
            navigate('/login', { replace: true });

        }
        else {
            console.log('AccountPopover.js -User loged');
            setuserName(JSON.parse(localStorage.getItem('mYLog')).displayName)
            setuserImage(JSON.parse(localStorage.getItem('mYLog')).profileImage)
            setloginuserDetailId(JSON.parse(localStorage.getItem('mYLog')).UId)
            setlastActiveIn(JSON.parse(localStorage.getItem('mYLog')).lastActiveIn)
            // { dayjs(date).format('DD-MMM') }
            // console.log(dayjs(lastActiveIn).format('DD'));
            fetchUserData(JSON.parse(localStorage.getItem('mYLog')).UId)
        }

    };

    const fetchNonBillView = (cnt) => {
        console.log('logid11');
        console.log('1111111');
        setselmonths(cnt);
        fetch(`${variables.API_URL}DailyAccounts/Attendance`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                UserDetailId: 0,
                PunchType: cnt,
            })
        })



            // fetch(`${variables.API_URL}DailyAccounts/GetUserDetails`)
            .then((response) => response.json())
            .then(
                (data) => setUserAccount(data));
        // console.log('GetUserDetails');
        console.log(account);
    };

    useEffect(() => {
        const b = JSON.parse(localStorage.getItem('mYLog'));
        console.log("100 Days - Login");
        // console.log( { b });
        if (b !== null) {
           
                updateDashboard();
            
            // console.log('From Dash');
            // console.log(Userrr);
        }
        else {
            console.log('DashboardAppPage.js - No user loged');
            navigate('/login', { replace: true });
        }


    }, []);
    // *** End Edwin



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = ACCOINT_TABLE.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };



    // setopeningCash(account[0].opcash);

    // console.log(ss);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - account.length) : 0;

    const filteredUsers = applySortFilter(account, getComparator(order, orderBy), filterName);


    const saleGrapfDate = GetGraphDataDate(account, getComparator(order, orderBy), filterName);
    const saleGrapf = GetGraphData(account, getComparator(order, orderBy), filterName);
    const takeGrapf = GetGraphDataTake(account, getComparator(order, orderBy), filterName);
    const purchaseGrapf = GetGraphDataPurchase(account, getComparator(order, orderBy), filterName);
    const paymentGrapf = GetGraphDataPayment(account, getComparator(order, orderBy), filterName);


    const purGrapf = GetGraphDataPur(account, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    const saleIncrease1 = Math.round(((accountStatus[0].monthlyAVGSales - accountStatus[0].last_MonthlyAVGSales) / accountStatus[0].last_MonthlyAVGSales) * 100);
    const saleIncrease = `(${saleIncrease1}%) than last year`
    const monTotSale = accountStatus[0].monthlyTotalSales
    const monTotPur = accountStatus[0].purchase
    const monTotPay = accountStatus[0].payments


    const [DailyAccount, setDailyAccount] = useState([
        {
            id: 1,
            oPCash: 0,
        },
    ]);


    function updateDashboard() {

        console.log('Attendance');
        chkUserLogin();
        fetchNonBillView(selmonths);



        // fetchUserData(loginuserDetailId);

        // console.log(accountStatus[0].shiftopcash);
        // setopeningCash(account[0].opcash);
        // console.log('saleGrapf');
        // console.log(saleGrapf);
        // console.log(saleGrapfDate);

        console.log('Userrr[0]')
        console.log(loginuserDetailId)
    }

    
    function NonBillUpdate(crusid, crno) {
        console.log('NonBillUpdate');
        console.log(crno);
        console.log(crusid);
        if (crno === '' || crno === 0) {
            alert('Please enter Cr list number');
        }
        else {
            fetch(`${variables.API_URL}DailyAccounts/UpdateNonBill`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    UserDetailId: crno,
                    PunchType: crusid,
                })
            })
                .then(res => { return res.json(); })
                .then((result) => {
                    //* console.log(result);
                    // if (result.interactID === 300) { alert(result.message); }
                    // else {
                    alert(result.message);
                    updateDashboard();
                    // }
                }, (error) => { alert(error); })
        }
    };


    return (
        <>
            <Helmet>
                <title> Attendance | {variables.COMPANY_NAME} </title>
            </Helmet>
            <div>

                <Container maxWidth="xl">
                    <Grid container spacing={3} padding={1}>
                        
                        
                            <Container>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom>
                                    Attendance
                                    </Typography>
                                
                                    <Button variant="contained" size='small'
                                        onClick={() => { fetchNonBillView(1) }}

                                        startIcon={<Iconify icon="eva:plus-fill" />}>
                                        1 Month Records
                                    </Button>
                                    <Button variant="contained" size='small'
                                        onClick={() => { fetchNonBillView(2) }}

                                        startIcon={<Iconify icon="eva:plus-fill" />}>
                                        2 Month Records
                                    </Button>
                                    <Button variant="contained" size='small'
                                        onClick={() => { fetchNonBillView(3) }}

                                        startIcon={<Iconify icon="eva:plus-fill" />}>
                                        3 Month Records
                                    </Button>
                                    <Button variant="contained" size='small'
                                        onClick={() => { fetchNonBillView(6) }}

                                        startIcon={<Iconify icon="eva:plus-fill" />}>
                                        6 Month Records
                                    </Button>
                                <Button variant="contained" size='small'
                                    onClick={() => { fetchNonBillView(0) }}

                                    startIcon={<Iconify icon="eva:plus-fill" />}>
                                    Full Records
                                </Button>

                                </Stack>

                                <Card>
                                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />


                                    <Scrollbar>
                                        <Paper style={{ overflow: 'auto', width: '100%' }}>
                                            <TableContainer sx={{ minWidth: 800 }}>

                                                <Table stickyHeader aria-label="sticky table">
                                                    <UserListHeadNNP
                                                        order={order}
                                                        orderBy={orderBy}
                                                        // headLabel={TABLE_HEAD}
                                                        headLabel={ACCOINT_TABLE}
                                                        rowCount={account.length}
                                                        numSelected={selected.length}
                                                        onRequestSort={handleRequestSort}
                                                        onSelectAllClick={handleSelectAllClick}
                                                    />
                                                    <TableBody>

                                                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                            const { no, punchin, punchout, id,  hours, cashin, totalIn, adjest
                                                                , pur, card, gpay, oldbill, oldbillstat, advused, exp, crout, take, cashbox, totalOut, name, status
                                                                , avatarUrl, crintxt, advancetxt, purchasetxt, advanceusedtxt, creditouttxt, userDetailId
                                                                , dailyAdvTrans, dailyCrInTrans, dailyPurchTrans, dailyCrOutTrans, dailyAdvUsTrans } = row;

                                                            const shorten = name ? name.substring(0, 3) : '';

                                                            const selectedUser = selected.indexOf(name) !== -1;
                                                            console.log(punchout    );
                                                            // console.log(Userrr[0].userPunchiN);
                                                            // console.log(index);
                                                            // console.log(dailyCrInTrans);
                                                            return (

                                                                <TableRow hover key={no} tabIndex={-1} >
                                                                    <TableCell align="right">{no}</TableCell>
                                                                    <TableCell align="left">
                                                                        {(punchin !==null && dayjs(punchin).format('DD-MMM-YYYY - HH:MM') || "")}
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {
                                                                            (punchout !== null && dayjs(punchout).format('DD-MMM-YYYY - HH:MM') || 
                                                                                <Button className={(oldbillstat === 0 && 'pend') || (oldbillstat === 1 && 'succ') || 'pend'}
                                                                                    onClick={() => { NonBillUpdate(2, no) }}>
                                                                                    Pending
                                                                                </Button>
                                                                            )
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="right">{id}</TableCell>                                                                  
                                                                    
                                                                    
                                                                    <TableCell align="center">
                                                                        
                                                                            {<Tooltip title={shorten} followCursor>
                                                                                {
                                                                                    <Avatar alt={name} src={avatarUrl} />
                                                                                }
                                                                            </Tooltip>}
                                                                        {name}
                                                                    </TableCell>
                                                                    <TableCell align="right">{hours}</TableCell>
                                                                   

                                                                   
                                                                </TableRow>

                                                            );
                                                        })}
                                                        
                                                        {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )}

                                                    </TableBody>

                                                    {isNotFound && (
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                                    <Paper
                                                                        sx={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        <Typography variant="h6" paragraph>
                                                                            Not found
                                                                        </Typography>

                                                                        <Typography variant="body2">
                                                                            No results found for &nbsp;
                                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                                            <br /> Try checking for typos or using complete words.
                                                                        </Typography>
                                                                    </Paper>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    )}
                                                </Table>

                                            </TableContainer>
                                        </Paper>
                                    </Scrollbar>

                                    <TablePagination
                                        rowsPerPageOptions={[10, 30, 65]}
                                        component="div"
                                        count={account.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />

                                </Card>

                            </Container>
                       
                       
                        <Grid item xs={12} md={6} lg={8}>
                            <AppWebsiteVisits
                                title="Daily Sales & Purchase"
                                subheader={saleIncrease}
                                chartLabels={saleGrapfDate}
                                chartData={[
                                    {
                                        name: 'Purchase',
                                        type: 'column',
                                        fill: 'gradient',
                                        data: purchaseGrapf,
                                    },
                                    {
                                        name: 'Sales',
                                        type: 'column',
                                        fill: 'gradient',
                                        data: saleGrapf,
                                    },


                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <AppCurrentVisits
                                title="Current Visits"
                                chartData={[

                                    { label: '', value: 0 },
                                    { label: 'Sales', value: monTotSale },
                                    { label: 'Purchase', value: monTotPur },
                                    { label: '', value: 0 },

                                ]}
                                chartColors={[
                                    theme.palette.primary.main,
                                    theme.palette.info.main,
                                    theme.palette.error.main,
                                    theme.palette.warning.main,

                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={8}>
                            <AppWebsiteVisits
                                title="Daily Purchase & Payments"
                                subheader={saleIncrease}
                                chartLabels={saleGrapfDate}
                                chartData={[
                                    {
                                        name: 'Purchase',
                                        type: 'column',
                                        fill: 'gradient',
                                        data: purchaseGrapf,
                                    },
                                    {
                                        name: 'Payment',
                                        type: 'column',
                                        fill: 'gradient',
                                        data: paymentGrapf,
                                    },

                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <AppCurrentVisits
                                title="Current Visits"
                                chartData={[
                                    { label: '', value: 0 },
                                    { label: 'Purchase', value: monTotPur },
                                    { label: 'Payment', value: monTotPay },
                                    { label: '', value: 0 },

                                ]}
                                chartColors={[
                                    theme.palette.info.main,
                                    theme.palette.primary.main,
                                    theme.palette.warning.main,
                                    theme.palette.error.main,

                                ]}
                            />
                        </Grid>


                        {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: monTotSale },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid> */}

                        {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

                        {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

                        {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

                        {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid> */}


                    </Grid>
                </Container>



            </div>
        </>
    );
}
