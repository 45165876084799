import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';

// ** Date Formating
import dayjs from "dayjs";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    Tooltip,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    TextField,
    Select,
} from '@mui/material';
import { GetCrDetails, GetCrOutDetails, GetnonpayCrOutDetails, UpdateCrOut } from '../components/api/DailyAccountApiService';
import Loader from '../components/loader/Loader';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHeadMy, UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// mock
// import USERLIST from '../_mock/user';
import { variables } from '../Variables';
import "./AdvanceView.css";
// ----------------------------------------------------------------------

const ADV_TABLE_HEAD = [
    { id: 'crid', label: 'No', alignRight: false },
    { id: 'date', label: 'Cr: Date', alignRight: false },
    { id: 'crby', label: 'Cr: By', alignRight: false },
    { id: 'cramt', label: 'Cr: Amt', alignRight: false },
    { id: 'cruddt', label: 'Cr: Used On', alignRight: false },
    { id: 'crudby', label: 'Cr Used By', alignRight: false },
    { id: 'crudamt', label: 'Cr Used Amt', alignRight: false },
    { id: 'crused', label: 'Cr Used?', alignRight: false },
    { id: '' },
];
const ADV_PAID_TABLE_HEAD = [
    { id: 'crusid', label: 'No', alignRight: false },
    { id: 'crusdate', label: 'Cr: Date', alignRight: false },
    { id: 'crby', label: 'Cr: By', alignRight: false },
    { id: 'crusamt', label: 'Cr: Amt', alignRight: false },
    { id: 'crususer', label: 'User', alignRight: false },
    
];

// ----------------------------------------------------------------------

function crdescendingComparator(a, b, crorderBy) {
    if (b[crorderBy] < a[crorderBy]) {
        return -1;
    }
    if (b[crorderBy] > a[crorderBy]) {
        return 1;
    }
    return 0;
}
function croutdescendingComparator(a, b, croutorderBy) {
    if (b[croutorderBy] < a[croutorderBy]) {
        return -1;
    }
    if (b[croutorderBy] > a[croutorderBy]) {
        return 1;
    }
    return 0;
}
function nonpaycroutdescendingComparator(a, b, nonpaycroutorderBy) {
    if (b[nonpaycroutorderBy] < a[nonpaycroutorderBy]) {
        return -1;
    }
    if (b[nonpaycroutorderBy] > a[nonpaycroutorderBy]) {
        return 1;
    }
    return 0;
}
function crgetComparator(order, crorderBy) {
    return order === 'desc'
        ? (a, b) => crdescendingComparator(a, b, crorderBy)
        : (a, b) => -crdescendingComparator(a, b, crorderBy);
}
function croutgetComparator(order, croutorderBy) {
    return order === 'desc'
        ? (a, b) => croutdescendingComparator(a, b, croutorderBy)
        : (a, b) => -croutdescendingComparator(a, b, croutorderBy);
}
function nonpaycroutgetComparator(order, nonpaycroutorderBy) {
    return order === 'desc'
        ? (a, b) => nonpaycroutdescendingComparator(a, b, nonpaycroutorderBy)
        : (a, b) => -nonpaycroutdescendingComparator(a, b, nonpaycroutorderBy);
}
function applySortFilter(array, comparator, query) {
    console.log('applySortFilter');
    console.log(array);

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.by.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}
function applySortFilter2(array, comparator, query) {
    console.log('applySortFilter2');
    console.log(array);

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.usby.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

const dropdownOptions = [
    { value: 'KO', label: 'KO' },
    { value: 'MD', label: 'MD' },
];

export default function UserPage() {
    const current = new Date();
    current.setDate(current.getDate() - 0)

    // const [open, setOpen] = useState(null);
    const [crno, setcrno] = useState('');

    const [crpage, setcrpage] = useState(0);
    const [croutpage, setcroutpage] = useState(0);
    const [nonpaycroutpage, setnonpaycroutpage] = useState(0);

    const [crorder, setcrorder] = useState('asc');
    const [croutorder, setcroutorder] = useState('asc');
    const [nonpaycroutorder, setnonpaycroutorder] = useState('asc');

    const [crselected, setcrselected] = useState([]);
    const [croutselected, setcroutselected] = useState([]);
    const [nonpaycroutselected, setnonpaycroutselected] = useState([]);

    const [crorderBy, setcrorderBy] = useState('crid');
    const [croutorderBy, setcroutorderBy] = useState('crid');
    const [nonpaycroutorderBy, setnonpaycroutorderBy] = useState('crid');

    const [crfilterName, setcrfilterName] = useState('');
    const [croutfilterName, setcroutfilterName] = useState('');
    const [nonpaycroutfilterName, setnonpaycroutfilterName] = useState('');

    const [crrowsPerPage, setcrrowsPerPage] = useState(10);
    const [croutrowsPerPage, setcroutrowsPerPage] = useState(10);
    const [nonpaycroutrowsPerPage, setnonpaycroutrowsPerPage] = useState(10);
    const [loginuserDetailId, setloginuserDetailId] = useState(localStorage.getItem('userId'));
    const [loader, setLoader] = useState(false);

    const crhandleRequestSort = (event, property) => {
        const isAsc = crorderBy === property && crorder === 'asc';
        setcrorder(isAsc ? 'desc' : 'asc');
        setcrorderBy(property);
    };
    const crouthandleRequestSort = (event, property) => {
        const isAsc = croutorderBy === property && croutorder === 'asc';
        setcroutorder(isAsc ? 'desc' : 'asc');
        setcroutorderBy(property);
    };
    const nonpaycrouthandleRequestSort = (event, property) => {
        const isAsc = nonpaycroutorderBy === property && nonpaycroutorder === 'asc';
        setnonpaycroutorder(isAsc ? 'desc' : 'asc');
        setnonpaycroutorderBy(property);
    };
    const crhandleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = Crlist.map((n) => n.crid);
            crselected(newSelecteds);
            return;
        }
        crselected([]);
    };
    const crouthandleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = CrOutlist.map((n) => n.crusid);
            croutselected(newSelecteds);
            return;
        }
        croutselected([]);
    };

    const nonpaycrouthandleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = nonpayCrOutlist.map((n) => n.crusid);
            nonpaycroutselected(newSelecteds);
            return;
        }
        nonpaycroutselected([]);
    };
    const crhandleChangePage = (event, newPage) => {
        setcrpage(newPage);
    };
    const crouthandleChangePage = (event, newPage) => {
        setcroutpage(newPage);
    };
    const nonpaycrouthandleChangePage = (event, newPage) => {
        setnonpaycroutpage(newPage);
    };
    const crhandleChangeRowsPerPage = (event) => {
        setcrpage(0);
        setcrrowsPerPage(parseInt(event.target.value, 10));
    };
    const crouthandleChangeRowsPerPage = (event) => {
        setcroutpage(0);
        setcroutrowsPerPage(parseInt(event.target.value, 10));
    };
    const nonpaycrouthandleChangeRowsPerPage = (event) => {
        setnonpaycroutpage(0);
        setnonpaycroutrowsPerPage(parseInt(event.target.value, 10));
    };
    const crhandleFilterByNamepage = (event) => {
        setcrpage(0);
        setcrfilterName(event.target.value);
    };
    const crouthandleFilterByNamepage = (event) => {
        setcroutpage(0);
        setcroutfilterName(event.target.value);
    };
    const nonpaycrouthandleFilterByNamepage = (event) => {
        setnonpaycroutpage(0);
        setnonpaycroutfilterName(event.target.value);
    };
    const [Crlist, setCrlist] = useState([{}]);
    const [CrOutlist, setCrOutlist] = useState([{}]);
    const [nonpayCrOutlist, setnonpayCrOutlist] = useState([{}]);
    const navigate = useNavigate();


    async function CrisUSed(crusid, crno) {
        if (loginuserDetailId > 3 && crno<=1)
        {
            alert('Not a valid number');
        }        
        else if (crno === '' || crno === 0) {
            alert('Please enter Cr list number');
        }
        else {
            try {
                setLoader(true);
                const res = await UpdateCrOut(crno, crusid);
                if (res.data) {
                    alert(res.data.StatusMessage);
                    updateDashboard();
                }
                setLoader(false)
            } catch (error) {
                console.error('Error in update CrOutData:', error);
            }

            // fetch(`${variables.API_URL}DailyAccounts/UpdateCrOut`, {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //         UserDetailId: crno,
            //         PunchType: crusid,
            //     })
            // })
            //     .then(res => { return res.json(); })
            //     .then((result) => {
            //         //* console.log(result);
            //         // if (result.interactID === 300) { alert(result.message); }
            //         // else {
            //         alert(result.message);
            //         updateDashboard();
            //         // }
            //     }, (error) => { alert(error); })
        }
    };

    const fetchCrData = async (shop) => {
        try {
            let shopId;
            if (loginuserDetailId <= 3) {
                if (shop) shopId = shop;
                else shopId = "KO";
            }
            else {
                shopId = localStorage.getItem('shopId');
                if (Number(shopId) === 1) {
                    shopId = 'KO';
                }
            }
            setLoader(true);
            const res = await GetCrDetails(1, shopId);
            if (res.data) {
                setCrlist(res.data)
            }
            setLoader(false)
        } catch (error) {
            console.error('Error in fetchCrData:', error);
        }
        // fetch(`${variables.API_URL}DailyAccounts/GetCrDetails`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         UserDetailId: 0,
        //         PunchType: 1,
        //     })
        // })

        //     .then((response) => response.json())
        //     .then(
        //         (data) => setCrlist(data));

        // console.log('GetUserDetails');
        // console.log(Userrr);
    };

    const fetchCrOutData = async (shop) => {
        try {
            let shopId;
            if (loginuserDetailId <= 3) {
                if (shop) shopId = shop;
                else shopId = "KO";
            }
            else {
                shopId = localStorage.getItem('shopId');
                if (Number(shopId) === 1) {
                    shopId = 'KO';
                }
            }
            setLoader(true);
            const res = await GetCrOutDetails(1,shopId);
            if (res.data) {
                setCrOutlist(res.data)
            }
            setLoader(false);
        } catch (error) {
            console.error('Error in fetchCROUtData:', error);
        }
        // fetch(`${variables.API_URL}DailyAccounts/GetCrOutDetails`, {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         UserDetailId: 0,
        //         PunchType: 0,
        //     })
        // })

        //     .then((response) => response.json())
        //     .then(
        //         (data) => setCrOutlist(data));
        // console.log('GetUserDetails');
        // console.log(Userrr);

    };
    const fetchnonpayCrOutData = async (shop) => {
        try {
            let shopId;
            if (loginuserDetailId <= 3) {
                if (shop) shopId = shop;
                else shopId = "KO";
            }
            else {
                shopId = localStorage.getItem('shopId');
                if (Number(shopId) === 1) {
                    shopId = 'KO';
                }
            }
            setLoader(true);
            const res = await GetnonpayCrOutDetails(1, shopId);
            if (res.data) {
                setnonpayCrOutlist(res.data)
            }
            setLoader(false);
        } catch (error) {
            console.error('Error in fetchnonpayCROUtData:', error);
        }


    };
    function updateDashboard() {

        console.log('Cr List page');
        fetchCrData(selectedValue);
        fetchCrOutData(selectedValue);
        fetchnonpayCrOutData(selectedValue);
    }

    useEffect(() => {
        const b = JSON.parse(localStorage.getItem('mYLog'));
        console.log("Cr List - Login");
        // console.log( { b });
        if (b !== null) {
            updateDashboard();
            // console.log('From Dash');
            // console.log(Userrr);
        }
        else {
            console.log('DashboardAppPage.js - No user loged');
            navigate('/login', { replace: true });
        }


    }, []);

    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    // const isNotFound = !filteredUsers.length && !!filterName;


    const emptyRowscr = crpage > 0 ? Math.max(0, (1 + crpage) * crrowsPerPage - Crlist.length) : 0;

    const filteredUserscr = applySortFilter(Crlist, crgetComparator(crorder, crorderBy), crfilterName);

    const isNotFoundcr = !filteredUserscr.length && !!crfilterName;

    // *** 2
    const emptyRowscrout = croutpage > 0 ? Math.max(0, (1 + croutpage) * croutrowsPerPage - CrOutlist.length) : 0;

    const filteredUserscrout = applySortFilter2(CrOutlist, croutgetComparator(croutorder, croutorderBy), croutfilterName);

    const isNotFoundcrout = !filteredUserscrout.length && !!croutfilterName;

    // **** 3
    const emptyRowsnonpaycrout = nonpaycroutpage > 0 ? Math.max(0, (1 + nonpaycroutpage) * nonpaycroutrowsPerPage - nonpayCrOutlist.length) : 0;

    const filteredUsersnonpaycrout = applySortFilter2(nonpayCrOutlist, nonpaycroutgetComparator(nonpaycroutorder, nonpaycroutorderBy), nonpaycroutfilterName);

    const isNotFoundnonpaycrout = !filteredUsersnonpaycrout.length && !!nonpaycroutfilterName;

    
// **** 
    const [selectedValue, setSelectedValue] = useState('KO');

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
        fetchCrData(event.target.value);
        fetchCrOutData(event.target.value);
        fetchnonpayCrOutData(event.target.value);
    };

    return (
        <>
            <Helmet>
                <title> Cr | {variables.COMPANY_NAME} </title>
            </Helmet>
            <Container>
                Update as "1" for all non available Cr's
                <Stack direction="row" alignItems="center">
                    <Typography variant="h4" gutterBottom mr={5}>
                        Cr's Out List
                    </Typography>
                    {loginuserDetailId <= 3 ? (
                        <Stack direction="row" alignItems="center" mb={2}>
                            <Typography variant="p" gutterBottom mr={2}>
                                Filter By :
                            </Typography>

                            <Select width={10} value={selectedValue} onChange={handleDropdownChange}>
                                {dropdownOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    ) : ('')
                    }
                </Stack>

                <Card>
                    <UserListToolbar numSelected={croutselected.length} filterName={croutfilterName} onFilterName={crouthandleFilterByNamepage} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHeadMy
                                    croutorder={croutorder}
                                    croutorderBy={croutorderBy}
                                    headLabel={ADV_PAID_TABLE_HEAD}
                                    rowCount={CrOutlist.length}
                                    numSelected={croutselected.length}
                                    onRequestSort={crouthandleRequestSort}
                                    onSelectAllClick={crouthandleSelectAllClick}
                                />
                                {loader ? (<div className="table-loader">
                                    <Loader />
                                </div>
                                ) : (
                                    <TableBody>
                                        {filteredUserscrout.slice(croutpage * croutrowsPerPage, croutpage * croutrowsPerPage + croutrowsPerPage).map((row) => {

                                            const { usid, tranid, usdate, usamt, usby, username, avatarUrl } = row;

                                            const selectedUser = croutselected.indexOf(usid) !== -1;
                                            const shorten = username ? username.substring(0, 3) : '';
                                            // console.log(filteredUsers1)


                                            // console.log(date1)
                                            // console.log(date2)
                                            return (
                                                <TableRow hover key={usid} tabIndex={-1} >
                                                    <TableCell align="left">{usid}</TableCell>
                                                    <TableCell align="left">{dayjs(usdate).format('DD-MMM')}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none" width={10}>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={usby} src={'avatarUrl'} />
                                                            <Typography variant="subtitle2" noWrap>
                                                                {usby}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>


                                                    <TableCell align="left">{usamt}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {<Tooltip title={shorten} followCursor>
                                                                {
                                                                    <Avatar alt={username} src={avatarUrl} />
                                                                }
                                                            </Tooltip>}
                                                        </Stack>
                                                    </TableCell>


                                                    <TableCell align="left">


                                                        <TextField variant="outlined" className='hf' type="number" size="small" name="crno"

                                                            onChange={(e) => { setcrno(e.target.value) }}
                                                        />
                                                        <Button variant="contained" size='small'
                                                            onClick={() => { CrisUSed(usid, crno) }}

                                                            startIcon={<Iconify icon="eva:plus-fill" />}>
                                                            Update
                                                        </Button>
                                                    </TableCell>




                                                </TableRow>
                                            );
                                        })}
                                        {emptyRowscrout > 0 && (
                                            <TableRow style={{ height: 53 * emptyRowscrout }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                                {isNotFoundcrout && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{croutfilterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 30]}
                        component="div"
                        count={CrOutlist.length}
                        rowsPerPage={croutrowsPerPage}
                        page={croutpage}
                        onPageChange={crouthandleChangePage}
                        onRowsPerPageChange={crouthandleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Cr's In List
                    </Typography>

                </Stack>

                <Card>
                    <UserListToolbar numSelected={crselected.length} crfilterName={crfilterName} onFilterName={crhandleFilterByNamepage} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHeadMy
                                    crorder={crorder}
                                    crorderBy={crorderBy}
                                    headLabel={ADV_TABLE_HEAD}
                                    rowCount={Crlist.length}
                                    numSelected={crselected.length}
                                    onRequestSort={crhandleRequestSort}
                                    onSelectAllClick={crhandleSelectAllClick}
                                />
                                {loader ? (<div className="table-loader">
                                    <Loader />
                                </div>
                                ) : (
                                    <TableBody>
                                        {filteredUserscr.slice(crpage * crrowsPerPage, crpage * crrowsPerPage + crrowsPerPage).map((row) => {

                                            const { id, date, by, amt, useddt, used, usedby, usedamt, username, avatarUrl } = row;

                                            const selectedUser = crselected.indexOf(id) !== -1;
                                            const shorten = username ? username.substring(0, 3) : '';

                                            return (
                                                <TableRow hover key={id} tabIndex={-1} >
                                                    <TableCell align="left">{id}</TableCell>
                                                    <TableCell align="left">{dayjs(date).format('DD-MMM')}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={by} src={'avatarUrl'} />
                                                            <Typography variant="subtitle2" noWrap>
                                                                {by}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                               

                                                    <TableCell align="left">{amt}</TableCell>
                                                    <TableCell align="left">{dayjs(useddt === null && '' || useddt).format('DD-MMM')}</TableCell>
                                                    <TableCell align="left">{usedby}</TableCell>
                                                    <TableCell align="left">{usedamt}</TableCell>
                                                    <TableCell align="left">{used === 1 && 'Yes' || ''}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {<Tooltip title={shorten} followCursor>
                                                                {
                                                                    <Avatar alt={username} src={avatarUrl} />
                                                                }
                                                            </Tooltip>}
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRowscr > 0 && (
                                            <TableRow style={{ height: 53 * emptyRowscr }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                                {isNotFoundcr && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{crfilterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 30]}
                        component="div"
                        count={Crlist.length}
                        rowsPerPage={crrowsPerPage}
                        page={crpage}
                        onPageChange={crhandleChangePage}
                        onRowsPerPageChange={crhandleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Container>
                <Stack direction="row" alignItems="center">
                    <Typography variant="h4" gutterBottom mr={5}>
                        Pending Cr's
                    </Typography>
                    
                    {loginuserDetailId <= 3 ? (
                        <Stack direction="row" alignItems="center" mb={2}>
                            <Typography variant="p" gutterBottom mr={2}>
                                Filter By :
                            </Typography>

                            <Select width={10} value={selectedValue} onChange={handleDropdownChange}>
                                {dropdownOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    ) : ('')
                    }
                </Stack>

                <Card>
                    <UserListToolbar numSelected={nonpaycroutselected.length} filterName={nonpaycroutfilterName} onFilterName={nonpaycrouthandleFilterByNamepage} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHeadMy
                                    nonpaycroutorder={nonpaycroutorder}
                                    nonpaycroutorderBy={nonpaycroutorderBy}
                                    headLabel={ADV_PAID_TABLE_HEAD}
                                    rowCount={nonpayCrOutlist.length}
                                    numSelected={nonpaycroutselected.length}
                                    onRequestSort={nonpaycrouthandleRequestSort}
                                    onSelectAllClick={nonpaycrouthandleSelectAllClick}
                                />
                                {loader ? (<div className="table-loader">
                                    <Loader />
                                </div>
                                ) : (
                                    <TableBody>
                                            {filteredUsersnonpaycrout.slice(nonpaycroutpage * nonpaycroutrowsPerPage, nonpaycroutpage * nonpaycroutrowsPerPage + nonpaycroutrowsPerPage).map((row) => {

                                            const { usid, tranid, usdate, usamt, usby, username, avatarUrl } = row;

                                            const selectedUser = nonpaycroutselected.indexOf(usid) !== -1;
                                            const shorten = username ? username.substring(0, 3) : '';
                                            // console.log(filteredUsers1)


                                            // console.log(date1)
                                            // console.log(date2)
                                            return (
                                                <TableRow hover key={usid} tabIndex={-1} >
                                                    <TableCell align="left">{usid}</TableCell>
                                                    <TableCell align="left">{dayjs(usdate).format('DD-MMM')}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={usby} src={'avatarUrl'} />
                                                            <Typography variant="subtitle2" noWrap>
                                                                {usby}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>


                                                    <TableCell align="left">{usamt}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {<Tooltip title={shorten} followCursor>
                                                                {
                                                                    <Avatar alt={username} src={avatarUrl} />
                                                                }
                                                            </Tooltip>}
                                                        </Stack>
                                                    </TableCell>


                                                    {/* <TableCell align="left">


                                                        <TextField variant="outlined" className='hf' type="number" size="small" name="crno"

                                                            onChange={(e) => { setcrno(e.target.value) }}
                                                        />
                                                        <Button variant="contained" size='small'
                                                            onClick={() => { CrisUSed(usid, crno) }}

                                                            startIcon={<Iconify icon="eva:plus-fill" />}>
                                                            Update
                                                        </Button>
                                                    </TableCell> */}




                                                </TableRow>
                                            );
                                        })}
                                            {emptyRowsnonpaycrout > 0 && (
                                                <TableRow style={{ height: 53 * emptyRowsnonpaycrout }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                                {isNotFoundnonpaycrout && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{nonpaycroutfilterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 30]}
                        component="div"
                        count={nonpayCrOutlist.length}
                        rowsPerPage={nonpaycroutrowsPerPage}
                        page={nonpaycroutpage}
                        onPageChange={nonpaycrouthandleChangePage}
                        onRowsPerPageChange={nonpaycrouthandleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            
        </>
    );
}
